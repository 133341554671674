import { map, takeUntil } from 'rxjs/operators';
import { CodeValueGroups } from '../../model';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { PhxDataTableConfiguration, RowHighlightingConfig } from '../../model';
import { PhxDataTableColumn } from '../../model';
import { CommonService, WindowRefService, ApiService, PhxLocalizationService, PhxConstants } from '../../index';
import { CodeValueService } from '../../services/code-value.service';
import { PhxDataTableStateSavingMode } from '../../model';
import { OrganizationApiService } from '../../../organization/services/organization.api.service';
import { Router } from '@angular/router';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';

declare let oreq: any;

@Component({
  selector: 'app-associated-workorders',
  templateUrl: './associated-workorders.component.html',
  styleUrls: ['./associated-workorders.component.less']
})

export class AssociatedWorkordersComponent extends BaseComponentOnDestroy implements OnInit, OnDestroy {
  @Input() contactId: number;
  @Input() organizationId: number;
  dataTableConfiguration: PhxDataTableConfiguration = new PhxDataTableConfiguration({
    rowHighlightingConfig: new RowHighlightingConfig()
  });
  dataSourceUrl: string;
  dataGridComponentName = 'workorderSearch';
  internalOrganizations: any[] = [];
  oDataParams: any;
  columns: Array<PhxDataTableColumn>;

  constructor(
    public commonService: CommonService,
    private codeValueService: CodeValueService,
    private winRef: WindowRefService,
    private apiService: ApiService,
    private localizationService: PhxLocalizationService,
    private orgService: OrganizationApiService,
    private router: Router
  ) {
    super();
    this.dataTableConfiguration.stateSavingMode = PhxDataTableStateSavingMode.None;
  }

  ngOnInit() {
    if (this.organizationId) {
      this.dataSourceUrl = 'assignment/assignmentsByOrganizationId/' + this.organizationId;
    } else {
      this.dataSourceUrl = 'assignment/assignmentsByContactId/' + this.contactId;
    }

    this.loadDataTable();
  }

  loadDataTable() {
    this.oDataParams = oreq
      .request()
      .withSelect([
        'WorkOrderFullNumber',
        'AssignmentId',
        'WorkOrderId',
        'WorkOrderNumber',
        'StartDate',
        'EndDate',
        'WorkOrderStatus',
        'WorkOrderVersionId',
        'PaymentPrimaryRateSumPerRateUnit',
        'BillingPrimaryRateSumPerRateUnit',
        'WorkerName',
        'ClientName',
        'OrganizationIdInternal',
        'InternalCompanyDisplayName',
        'UserProfileIdWorker',
        'WorkerProfileType',
        'ManagerName',
        'TimeSheetApprover',
        'BranchId',
        'IsChangeInProgress',
        'WorkOrderLineOfBusiness',
        'JobOwnerName',
        'FirstRecruiterName',
        'ClientOrganizationId',
        'ContactId',
        'UserProfileTypeId',
        'IsTest'
      ])
      .url();
    this.orgService.getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveInternalRole()
      .pipe(
        takeUntil(this.isDestroyed$),
        map((data) => {
          return data.Items.map((item) => {
            return {
              text: item.DisplayName,
              value: item.Id
            };
          });
        }))
      .subscribe(res => {
        this.internalOrganizations = res;
        this.columns = this.buildColumns();
      });
  }

  buildColumns(): Array<PhxDataTableColumn> {
    return [
      new PhxDataTableColumn({
        dataField: 'WorkOrderFullNumber',
        caption: $localize`:@@common.number:Number`,
        calculateSortValue: 'AssignmentId'
      }),
      new PhxDataTableColumn({
        dataField: 'OrganizationIdInternal',
        caption: $localize`:@@common.internalCompany:Internal Company`,
        lookup: {
          dataSource: this.getInternalOrgLookup(),
          valueExpr: 'value',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'WorkerProfileType',
        caption: $localize`:@@common.profileType:Profile Type`,
        lookup: {
          dataSource: this.getUserProfileTypeLookup(),
          valueExpr: 'text',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'ClientName',
        caption: $localize`:@@common.clientName:Client Name`
      }),
      new PhxDataTableColumn({
        dataField: 'StartDate',
        caption: $localize`:@@common.startDate:Start Date`,
        dataType: 'date'
      }),
      new PhxDataTableColumn({
        dataField: 'EndDate',
        caption: $localize`:@@common.endDate:End Date`,
        dataType: 'date'
      }),
      new PhxDataTableColumn({
        dataField: 'BillingPrimaryRateSumPerRateUnit',
        caption: $localize`:@@common.billRate:Bill Rate`
      }),
      new PhxDataTableColumn({
        dataField: 'PaymentPrimaryRateSumPerRateUnit',
        caption: $localize`:@@common.payRate:Pay Rate`
      }),
      new PhxDataTableColumn({
        dataField: 'WorkOrderStatus',
        caption: $localize`:@@common.status:Status`,
        lookup: {
          dataSource: this.getWorkorderStatusLookup(),
          valueExpr: 'text',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'BranchId',
        caption: $localize`:@@common.branch:Branch`,
        lookup: {
          dataSource: this.getBranchLookup(),
          valueExpr: 'id',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'IsChangeInProgress',
        caption: $localize`:@@common.changeInProgress:Change In Progress`,
        dataType: 'boolean',
        lookup: {
          dataSource: this.getChangeInProgressLookup(),
          valueExpr: 'id',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'TimeSheetApprover',
        caption: $localize`:@@common.timesheetApprover:Timesheet Approver`
      }),
      new PhxDataTableColumn({
        dataField: 'ManagerName',
        caption: $localize`:@@common.clientManager:Client Manager`
      }),
      new PhxDataTableColumn({
        dataField: 'WorkOrderLineOfBusiness',
        caption: $localize`:@@common.lineOfBusiness:Line of Business`,
        lookup: {
          dataSource: this.getWorkOrderLineOfBusinessLookup(),
          valueExpr: 'text',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'JobOwnerName',
        caption: $localize`:@@common.jobOwner:Job Owner`
      }),
      new PhxDataTableColumn({
        dataField: 'FirstRecruiterName',
        caption: $localize`:@@common.recruiter:Recruiter`
      }),
      new PhxDataTableColumn({
        dataField: 'IsTest',
        caption: this.localizationService.translate('common.phxDataTable.implementationHeader'),
        dataType: 'boolean',
        lookup: {
          dataSource: PhxDataTableColumn.isTest.lookupDataSource(this.localizationService),
          valueExpr: 'value',
          displayExpr: 'text'
        }
      })
    ];
  }

  onRowClick(event: any) {
    if (event && event.data) {
      this.viewWorkOrderDetails(event.data.AssignmentId, event.data.WorkOrderId, event.data.WorkOrderVersionId);
    }
  }

  viewWorkOrderDetails(assignmentId: number, workOrderId: number, workOrderVersionId: number) {
    this.router.navigate(['next', 'workorder', assignmentId, workOrderId, workOrderVersionId, 'core']);
  }

  getWorkorderStatusLookup() {
    return this.codeValueService.getCodeValues(CodeValueGroups.WorkOrderStatus, true);
  }

  getWorkOrderLineOfBusinessLookup() {
    return this.codeValueService.getCodeValues(CodeValueGroups.LineOfBusiness, true).filter(item => item.id !== PhxConstants.LineOfBusiness.Expense);
  }

  getChangeInProgressLookup() {
    return [{ id: false, text: $localize`:@@common.message.no:No` }, { id: true, text: $localize`:@@common.message.yes:Yes` }];
  }

  getUserProfileTypeLookup() {
    return this.codeValueService.getCodeValues('usr.CodeProfileType', true);
  }

  getBranchLookup() {
    return this.codeValueService.getCodeValuesSortByCode('workorder.CodeInternalOrganizationDefinition1', true);
  }

  getInternalOrgLookup() {
    return this.internalOrganizations.sort(this.commonService.compareFnToSortObjects('text'));
  }

  onContextMenuPreparing(event: any) {
    if (event && event.row && event.row.rowType === 'data' && event.row.data.AssignmentId && event.row.data.WorkOrderId && event.row.data.WorkOrderVersionId) {
      event.items = [{
        text: $localize`:@@phx.associatedWorkorders.openWoInNewTab:Open work order in new tab`,
        onItemClick: () => {
          this.winRef.openUrl(`/#/next/workorder/${event.row.data.AssignmentId}/${event.row.data.WorkOrderId}/${event.row.data.WorkOrderVersionId}/core`);
        }
      }];
    }
  }
}
