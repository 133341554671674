/* eslint-disable no-underscore-dangle */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {
  DialogResultType,
  PhxConstants,
  PhxDataTableColumn,
  PhxDataTableConfiguration,
  PhxDataTableSelectionMode
} from '../../model';

import { DxComponent, DxDataGridComponent, DxTemplateHost, WatcherHelper } from 'devextreme-angular';
import { TransferState } from '@angular/platform-browser';
import { PhoenixCommonModuleResourceKeys } from '../../PhoenixCommonModule.resource-keys';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { environment } from '../../../../environments/environment';
import { CookieService } from '../../services/cookie.service';
import DevExpress from 'devextreme/bundles/dx.all';
import { takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { LoadingSpinnerService } from '../../loading-spinner/service/loading-spinner.service';
import { difference, union } from 'lodash';
import { CodeValueService } from '../../services/code-value.service';
import { AuthService } from '../../services/auth.service';
import { PhxLocalizationService } from '../../services/phx-localization.service';
import { DialogService } from '../../services/dialog.service';
import { Router } from '@angular/router';
import { WindowRefService } from '../../services/WindowRef.service';
import {
  ReportAZSearchRoutingConfiguration,
  ReportConfigurationDto
} from '../../../report-azsearch/model/report-azsearch-list';
import { ReportAZSearchService } from '../../../report-azsearch/service/report-azsearch.service';
import { enableColumnChooserSort } from '../../utility/data-grid-column-chooser-sort';
import LoadOptions = DevExpress.data.LoadOptions;

import ReportColumnType = PhxConstants.ReportColumnType;
import ReportType = PhxConstants.ReportType;

@Component({
  selector: 'app-phx-cognitive-search-grid',
  templateUrl: './phx-cognitive-search-grid.component.html',
  styleUrls: ['./phx-cognitive-search-grid.component.less']
})
export class PhxCognitiveSearchGridComponent extends DxComponent implements OnInit, AfterViewInit, OnDestroy {

  public isDestroyed$ = new Subject<boolean>();
  @ViewChild('grid') grid: DxDataGridComponent;
  @Input() reportTypeId: number;
  @Input() configuration: PhxDataTableConfiguration;
  @Input() preFilterOData: string;
  @Input() defaultSortList: Array<{ selector: string, desc: boolean }>;
  // Filtering inputs
  @Input() entityTypeId: number = null;
  @Input() entityId: number = null;
  @Input() userProfileIdWorker: number = null; // for the future in people work order tab - look into additional fields
  @Input() columnListOverride: string[] = null; // list of column names to override the default configuration
  columns: Array<PhxDataTableColumn>;
  dataSource: DataSource;
  dataSourceUrl: string;
  reportKeyColumn = 'EntityId';
  reportRoutingConfig: Array<ReportAZSearchRoutingConfiguration>;
  reportColumnConfig: Array<ReportConfigurationDto>;
  visibleGridColumns: Array<string> = [];
  selectColumns: string[];
  requiredColumns: Array<string>;
  cultureId: number;
  reportName: string;
  reportTitle: string;
  organizations: Array<{ text: string, value: string }> = [];
  branches: Array<{ text: string, value: string }> = [];
  dateColumnFormat = PhxConstants.DateFormat.mediumDate;
  commonModuleResourceKeys: typeof PhoenixCommonModuleResourceKeys;
  columnChooserConfig = {
    enabled: true,
    width: 300,
    height: 350,
    title: 'common.phxDataTable.columnChooserTitle',
    emptyPanelText: 'common.phxDataTable.columnChooserEmptyPanelText',
    mode: 'select',
    allowSearch: true
  };
  loadPanelConfig = {
    enabled: true,
    height: 90,
    indicatorSrc: './../../../../assets/loading.gif',
    showIndicator: true,
    showPane: true,
    text: this.locale.translate('common.phxDataTableConfiguration.loadPanelText'),
    width: 200,
    shading: false,
    shadingColor: null
  };
  combinedFilter: any; // defined as any in dx-data-grid

  constructor(
    private reportService: ReportAZSearchService,
    private loadingSpinnerService: LoadingSpinnerService,
    private codeValueService: CodeValueService,
    private authService: AuthService,
    private locale: PhxLocalizationService,
    private dialogService: DialogService,
    private router: Router,
    private winRef: WindowRefService,
    private eRef: ElementRef,
    ngZone: NgZone,
    private templateHost: DxTemplateHost,
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _watcherHelper: WatcherHelper,
    private cookieSvc: CookieService,
    transferState: TransferState,
    @Inject(PLATFORM_ID) platformId: any) {
    super(eRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
    this.commonModuleResourceKeys = PhoenixCommonModuleResourceKeys;
  }

  get pageIndex(): number {
    return this.grid.instance.pageIndex();
  }

  get pageSize(): number {
    return this.grid.instance.pageSize();
  }

  get lastPageIndex(): number {
    return Math.floor(this.grid.instance.totalCount() / this.grid.instance.pageSize());
  }

  get gridTotalCount(): number {
    return this.grid.instance.totalCount();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _createInstance(element: any, options: any) {
    return this.grid.instance;
  }

  ngOnInit(): void {
    this.loadingSpinnerService.show();
    enableColumnChooserSort();
    const reportCodeValue = this.reportService.getCodeValue('report.CodeReportType').find(c => c.id === this.reportTypeId);
    this.reportName = reportCodeValue && reportCodeValue.value;
    this.reportTitle = reportCodeValue && reportCodeValue.description;
    this.applyLocalization();
    this.reportKeyColumn = this.reportService.getReportKeyColumn(this.reportTypeId);
    this.authService.getUserContext().then(user => {
      this.cultureId = user.User.CultureId;
    });

    forkJoin(
      [
        this.reportService.getReportConfiguration(this.reportTypeId),
        this.reportService.getReportRoutingConfiguration(this.reportTypeId),
        this.reportService.getOrgListForDropdown(this.isDestroyed$),
        this.reportService.getClientOrgListForDropdown(this.isDestroyed$),
        this.reportService.getBranchListForDropdown(this.isDestroyed$)
      ])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([reportColumnConfig, reportRoutingConfig, organizations,clientOrganizations, branches]) => {
          this.reportColumnConfig = reportColumnConfig;
          this.reportRoutingConfig = reportRoutingConfig;
          this.requiredColumns = this.reportService.parseRequiredColumns(this.reportRoutingConfig);
          this.organizations = organizations;
          this.branches = branches;

          this.columns = this.reportService.buildColumns(this.reportColumnConfig, this.cultureId, this.reportName, organizations, clientOrganizations, branches);
          this.visibleGridColumns = reportColumnConfig.filter(x => x.visible && x.defaultView).map(val => val.columnName);
          this.selectColumns = this.getSelectableColumns(this.visibleGridColumns);
          this.createDataSource();
        }, () => this.loadingSpinnerService.hide()
      );
  }

  ngAfterViewInit(): void {
    const columnChooserView = (this.grid.instance as any).getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.option('position', { my: 'center', at: 'center' });
      columnChooserView._popupContainer.option('onShown', () => {
        this.beginUpdate();
      });
      columnChooserView._popupContainer.option('onHidden', () => {
        this.endUpdate();
      });
    }
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  goNextPage() {
    const currentPageIndex = this.grid.instance.pageIndex();
    if (currentPageIndex < this.lastPageIndex) {
      this.grid.instance.pageIndex(currentPageIndex + 1);
    }
  }

  goPreviousPage() {
    const currentPageIndex = this.grid.instance.pageIndex();
    if (currentPageIndex !== 0) {
      this.grid.instance.pageIndex(currentPageIndex - 1);
    }
  }

  onRowClick(event: any) {
    switch (this.reportTypeId) {
      case ReportType.WorkOrderReport:
        this.router.navigate([`/next/workorder/${event.data.AssignmentId}/${event.data.WorkOrderId}/${event.data.WorkOrderVersionId}`]);
        break;
    }
  }

  onToolbarPreparing(e) {
    this.reportService.onToolbarPreparing(e, this.configuration);
  }

  onContextMenuPreparing(event: any) {
    this.reportService.onContextMenuPreparing(event, this.reportRoutingConfig, this.configuration, this.reportName);
  }

  onEditorPreparing(event) {
    this.reportService.onEditorPreparing(event);
  }

  onContentReady() {
    this.reportService.handleFilters(this.grid.instance);

    if (this.configuration && this.configuration.selectionMode && this.configuration.selectionMode !== PhxDataTableSelectionMode.None) {
      const newFilter = this.grid.instance.getCombinedFilter();
      if (!this.reportService.compareFilters(newFilter, this.combinedFilter)) {
        this.grid.instance.clearSelection();
      }
      this.combinedFilter = newFilter;
    }

    const visibleColumns = this.grid.instance.getVisibleColumns();
    if (visibleColumns && visibleColumns.length !== 0) {
      const visibleGridColumns = visibleColumns.map(x => x.dataField);
      if (visibleGridColumns.length !== this.visibleGridColumns.length || difference(visibleGridColumns, this.visibleGridColumns).length !== 0) {
        this.visibleGridColumns = visibleGridColumns;
        this.selectColumns = this.getSelectableColumns(this.visibleGridColumns);

        this.grid.instance.refresh();
      }
    }
  }

  resetGrid() {
    this.dialogService.confirm(this.locale.translate('common.phxDataTable.confirmResetViewTitle'), this.locale.translate('common.phxDataTable.confirmResetViewMessage'))
      .then((button) => {
        if (button === DialogResultType.Yes) {
          this.columns = this.reportService.buildColumns(this.reportColumnConfig, this.cultureId, this.reportName, this.organizations, this.branches);
          this.visibleGridColumns = this.reportColumnConfig.filter(x => x.visible && x.defaultView).map(val => val.columnName);
          this.selectColumns = this.getSelectableColumns(this.visibleGridColumns);

          this.grid.instance.refresh();
        }
      });
  }

  private createDataSource() {
    this.dataSource = new DataSource({
      store: new ODataStore({
        url: environment.reportServiceApiEndpoint + '/report/getReport/' + this.reportTypeId,
        key: this.reportKeyColumn,
        keyType: 'Int32',
        version: 4,
        beforeSend: (e) => {
          if (e.params.$filter) {
            e.params.$filter = '(' + this.preFilterOData + ') and (' + e.params.$filter + ')';
          } else {
            e.params.$filter = this.preFilterOData;
          }

          e.headers = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'azsearch-page-size': this.configuration.pageSize?.toString()
          };
        },
        onLoading: ((options: LoadOptions) => {
          options.select = this.selectColumns;
          options.sort = !options.sort || (options.sort && Array.isArray(options.sort) && options.sort.length === 0) ? this.defaultSortList : options.sort;
        }),
        onLoaded: (() => {
          this.loadingSpinnerService.hide();
        })
      })
    });
  }

  private applyLocalization() {
    this.columnChooserConfig.title = this.locale.translate(this.columnChooserConfig.title);
    this.columnChooserConfig.emptyPanelText = this.locale.translate(this.columnChooserConfig.emptyPanelText);
  }

  private getSelectableColumns(visibleColumns: Array<string>) {
    let selectableColumns = union(visibleColumns, this.requiredColumns, [this.reportKeyColumn], this.reportService.getExtraRequiredColumns(this.reportTypeId));
    const multiValueFields = this.reportColumnConfig.filter(x => x.columnTypeId === ReportColumnType.MultiValue);
    if (multiValueFields.length > 0) {
      const selectableMultiValueFields = selectableColumns.filter(x => multiValueFields.find(y => x === y.columnName));
      if (selectableMultiValueFields.length > 0) {
        selectableColumns = selectableColumns.concat(selectableMultiValueFields.map(x => {
          return x + 'DisplayValue';
        }));
      }
    }
    return selectableColumns;
  }

  private beginUpdate() {
    this.grid.instance.beginUpdate();
  }

  private endUpdate() {
    this.grid.instance.endUpdate();
  }
}
