<div class="ck-panel">
    <div class="ck-panel-body" *ngIf="!loadingList && !errorLoadingRules">
        <!-- NOTE: top status header -->
        <div class="status-container" [ngSwitch]="currentComplianceStatus">
            <ng-container *ngSwitchCase="ComplianceStatus.Compliant">
                <h3 class="ck-success">
                    <span class="material-icons-outlined">
                        check_circle
                    </span>
                    <span i18n="@@phx.panelChecklist.compliant">Compliant</span>
                </h3>
            </ng-container>

            <ng-container *ngSwitchCase="ComplianceStatus.Warning">
                <h3 class="ck-warn">
                    <span class="material-icons-outlined">
                        warning
                    </span>
                    <span i18n="@@phx.panelChecklist.compliantWithWarning">Compliant with Warning</span>
                </h3>
            </ng-container>

            <ng-container *ngSwitchCase="ComplianceStatus.NonCompliant">
                <h3 class="ck-critical">
                    <span class="material-icons-outlined">
                        cancel
                    </span>
                    <span i18n="@@phx.panelChecklist.nonCompliant">Non Compliant</span>
                </h3>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <h3 class="ck-none">
                    <span class="material-icons-outlined">
                        check_circle
                    </span>
                    <span i18n="@@phx.panelChecklist.compliancyRules">Compliancy Rules</span>
                </h3>
            </ng-container>
        </div>
        <!-- NOTE: search box and filter button -->
        <div class="search-box inline">
            <div class="search-input" [formGroup]="form">
                <phx-form-control [control]="form.get('keyword')" [editable]="true" [layoutType]="inputOnlyLayoutType">
                    <div class="input-group">
                        <input name="keyword" [formControl]="form.get('keyword')" type="text"
                            class="form-control border-radius-ltlb-4" placeholder="Search"
                            i18n-placeholder="@@common.search" />
                        <span class="input-group-addon border-radius-rtrb-4" style="padding:3px 6px;">
                            <i class="material-icons-outlined">search</i>
                        </span>
                    </div>
                </phx-form-control>
            </div>

            <div class='ck-expands'>
                <div class="btn-group">
                    <div class="btn-wrapper">
                        <button type="button"
                            class="btn btn-default btn-material margin-10-l button-34-34 filter-button dropdown-toggle "
                            [ngClass]="{
                                'has-filter': hidingCompliantRules || hidingWarningRules || hidingNoncompliantRules,
                                'is-success': currentComplianceStatus === ComplianceStatus.Compliant, 
                                'is-warning': currentComplianceStatus === ComplianceStatus.Warning, 
                                'is-critical': currentComplianceStatus === ComplianceStatus.NonCompliant
                            }" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <i class="material-icons">filter_alt</i>
                        </button>
                        <div class="filter-dropdown-menu dropdown-menu bordered dropdown-menu-right"
                            *ngIf="compliantTotal || warningTotal || nonCompliantTotal">
                            <ul>
                                <li class="action-link" [ngClass]="{'disabled':hidingCompliantRules}"
                                    (click)='filterStatus[0] = !filterStatus[0]; applyFiltersAndSearch()'
                                    *ngIf="compliantTotal">
                                    <span *ngIf="hidingCompliantRules"
                                        i18n="@@phx.panelChecklist.compliantHidden">Compliant rules hidden
                                        ({{compliantTotal}})</span>
                                    <span *ngIf="!hidingCompliantRules" i18n="@@phx.panelChecklist.hideCompliant">Hide
                                        compliant rules ({{compliantTotal}})</span>
                                </li>
                                <li class="action-link" [ngClass]="{'disabled':hidingWarningRules}"
                                    (click)='filterStatus[2] = !filterStatus[2]; applyFiltersAndSearch()'
                                    *ngIf="warningTotal">
                                    <span *ngIf="hidingWarningRules" i18n="@@phx.panelChecklist.warningHidden">Rules
                                        with warning hidden ({{warningTotal}})</span>
                                    <span *ngIf="!hidingWarningRules" i18n="@@phx.panelChecklist.hideWarning">Hide rules
                                        with warning ({{warningTotal}})</span>
                                </li>
                                <li class="action-link" [ngClass]="{'disabled':hidingNoncompliantRules}"
                                    (click)='filterStatus[1] = !filterStatus[1]; applyFiltersAndSearch()'
                                    *ngIf="nonCompliantTotal">
                                    <span *ngIf="hidingNoncompliantRules"
                                        i18n="@@phx.panelChecklist.nonCompliantHidden">Non-compliant rules hidden
                                        ({{nonCompliantTotal}})</span>
                                    <span *ngIf="!hidingNoncompliantRules"
                                        i18n="@@phx.panelChecklist.hideNonCompliant">Hide
                                        non-compliant rules ({{nonCompliantTotal}})</span>
                                </li>
                                <li class="action-link reset-link"
                                    *ngIf="hidingWarningRules || hidingNoncompliantRules || hidingCompliantRules"
                                    (click)='resetFilters()'>
                                    <span i18n="@@common.button.reset" class="btn btn-default">Reset</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ck-accord">
            <!-- NOTE: we hide non applicable rules - Default text when no rules are shown Story #42386 -->
            <div class="ck-accord-body rule-group-container" *ngIf="filteredRuleGroups?.length === 0">
                <div class="ck-rule padding-10-h">
                    <mat-icon fontSet="material-icons-outlined">
                        do_not_disturb_on
                    </mat-icon>
                    <div class="ck-text-disabled">
                        <p class="ck-text" i18n="@@phx.panelChecklist.noRulesApplicable">
                            No rules are applicable
                        </p>
                    </div>
                </div>
            </div>
            <!-- NOTE: looping through groups of rules -->
            <ng-container *ngIf="!hidingEverything">
                <div *ngFor="let group of filteredRuleGroups; trackBy: trackByFn"
                    [ngClass]="{'rule-group-container': group.rules?.length}">
                    <div *ngIf="!!group.rules?.length">
                        <app-phx-accordion [closeOthers]="false">
                            <app-phx-accordion-group #chk [(isOpen)]="group.isOpen" [showToggle]="false">
                                <div class="ck-accord-head" accordion-heading
                                    (click)="sendGoogleClickData('Collapse ' + group.category + ' group');">
                                    <ng-container
                                        *ngTemplateOutlet="groupComplianceIcon; context:{ $implicit: group.groupCompliance }"></ng-container>
                                    <span class="ck-title">
                                        {{ group.category}}
                                    </span>
                                    <span class="ck-count">({{group.compliantCount}}/{{group.length}})</span>
                                    <i class="material-icons header-glyphicon"
                                        *ngIf="!chk.isOpen">keyboard_arrow_down</i>
                                    <i class="material-icons header-glyphicon" *ngIf="chk.isOpen">keyboard_arrow_up</i>
                                </div>

                                <!-- Non-applicable rules do not show up anymore Story #42386 -->
                                <div class="ck-accord-body">
                                    <div *ngFor="let rule of group.rules; trackBy: trackByFn; let groupIndex = index">
                                        <ng-container *ngIf="!rule.rules?.length">
                                            <div class="ck-rule">
                                                <ng-container
                                                    *ngTemplateOutlet="isValidTemplate; context:{ $implicit: rule }"></ng-container>
                                                <ng-container
                                                    *ngTemplateOutlet="isNotValidTemplate; context:{ $implicit: rule  }"></ng-container>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="rule.rules?.length">
                                            <div [ngClass]="{'subrule-accordian-container': rule.isOpen}"
                                                class="document-rule-container">
                                                <div class="document-more-icon"
                                                    *ngIf="rule.additionalInformation.ComplianceDocumentId">
                                                    <div class="btn-group">
                                                        <div class="btn-wrapper">
                                                            <div class="dropdown-toggle " data-toggle="dropdown"
                                                                aria-haspopup="true" aria-expanded="true">
                                                                <mat-icon>more_vert</mat-icon>
                                                            </div>
                                                            <div
                                                                class="document-dropdown-menu  dropdown-menu bordered dropdown-menu-right">
                                                                <ul>
                                                                    <li>
                                                                        <a
                                                                            (click)="viewDocument(rule, rule.ruleSubCategoryId)">View
                                                                            document</a>
                                                                    </li>
                                                                    <li><a
                                                                            (click)="emitDocumentComplianceDataRefresh(rule)">Re-analyze
                                                                            document</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <app-phx-accordion [closeOthers]="true">
                                                    <app-phx-accordion-group #subrule [(isOpen)]="rule.isOpen"
                                                        [showToggle]="false"
                                                        panelClass="{{rule.isOpen ? 'compliance-doc-accordian-group' : 'closed-compliance-doc-accordian-group'}}">
                                                        <div class="ck-rule" accordion-heading
                                                            [ngClass]="{'has-subrules': rule.isOpen}">
                                                            <ng-container
                                                                *ngTemplateOutlet="groupComplianceIcon; context:{ $implicit: rule.groupCompliance }"></ng-container>
                                                            <div class="ck-text">
                                                                <p
                                                                    [ngClass]="{'open-compliance-doc-rule': rule.isOpen}">
                                                                    {{rule.ruleText}}</p>
                                                            </div>
                                                            <span
                                                                class="subgroup-count">({{rule.compliantCount}}/{{rule.length}})</span>
                                                            <i class="material-icons header-glyphicon"
                                                                *ngIf="!subrule.isOpen">keyboard_arrow_down</i>
                                                            <i class="material-icons header-glyphicon"
                                                                *ngIf="subrule.isOpen">keyboard_arrow_up</i>
                                                        </div>
                                                        <div *ngFor="let ruleRule of rule.rules; trackBy: trackByFn;let idx = index"
                                                            class="ck-sub-rule">
                                                            <ng-container
                                                                *ngTemplateOutlet="isValidTemplate; context:{ $implicit: ruleRule, index: idx, groupIndex:groupIndex  }"></ng-container>
                                                            <ng-container
                                                                *ngTemplateOutlet="isNotValidTemplate; context:{ $implicit: ruleRule, index: idx, groupIndex:groupIndex  }"></ng-container>
                                                        </div>
                                                    </app-phx-accordion-group>
                                                </app-phx-accordion>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </app-phx-accordion-group>
                        </app-phx-accordion>
                    </div>
                </div>
            </ng-container>
            <!-- NOTE: messaging when filter hides all rules -->
            <div class="everything-hidden-message rule-group-container"
                *ngIf="hidingEverything && filteredRuleGroups?.length" i18n="@@phx.panelChecklist.noRulesToDisplay">
                No rules to display. Please change the filter settings.
            </div>
        </div>
    </div>
    <!-- NOTE: error mesage when ruels cannot load -->
    <div class="status-container" *ngIf="errorLoadingRules">
        <h3 class="ck-critical">
            <span class="material-icons-outlined">
                cancel
            </span>
            <span i18n="@@phx.panelChecklist.unableToLoadRules" class="error-status">Sorry, unable to load compliancy
                rules.</span>
        </h3>
    </div>
    <!-- NOTE: loader -->
    <div class="loader-container" *ngIf="loadingList">
        <app-bouncing-balls [color]="loadingColor"></app-bouncing-balls>
    </div>
</div>

<!-- NOTE: rule is valid template -->
<ng-template #isValidTemplate let-rule="$implicit" let-index="index" let-groupIndex="groupIndex">
    <ng-container *ngIf="rule.isValid">
        <mat-icon *ngIf="rule.strictness !== RuleStrictness.NotApplicable" class="ck-success">
            done
        </mat-icon>
        <mat-icon fontSet="material-icons-outlined" *ngIf="rule.strictness === RuleStrictness.NotApplicable">
            do_not_disturb_on
        </mat-icon>
        <div
            [ngClass]="{'ck-text-disabled': rule.strictness === RuleStrictness.NotApplicable, 'ck-text': rule.strictness !== RuleStrictness.NotApplicable}">
            <p class="ck-passed">
                {{rule.ruleText}}
                <i *ngIf="rule.operation" [id]="'ruleinfo-' + index + '' + groupIndex"
                    class="material-icons md-18 icon-tooltip phx-dx-hover-only">info</i>
            </p>
            <ng-container
                *ngTemplateOutlet="providedExtractedToolTip; context:{ $implicit: rule, index: index, groupIndex: groupIndex  }"></ng-container>
        </div>
    </ng-container>
</ng-template>
<!-- NOTE: rule is not valid template -->
<ng-template #isNotValidTemplate let-rule="$implicit" let-index="index" let-groupIndex="groupIndex">
    <ng-container *ngIf="!rule.isValid">
        <mat-icon *ngIf="rule.strictness === RuleStrictness.Warning" class="ck-warn">
            warning
        </mat-icon>
        <mat-icon fontSet="material-icons-outlined" *ngIf="rule.strictness === RuleStrictness.NotApplicable">
            do_not_disturb_on
        </mat-icon>
        <mat-icon *ngIf="rule.strictness !== RuleStrictness.NotApplicable && rule.strictness !== RuleStrictness.Warning"
            class="ck-critical">
            close
        </mat-icon>
        <div class="ck-text">
            <p>{{rule.ruleText}}
                <i *ngIf="rule.operation" [id]="'ruleinfo-' + index + '' + groupIndex"
                    class="material-icons md-18 icon-tooltip phx-dx-hover-only">info</i>
            </p>
            <ng-container
                *ngTemplateOutlet="providedExtractedToolTip; context:{ $implicit: rule, index: index, groupIndex: groupIndex  }"></ng-container>
        </div>
    </ng-container>
</ng-template>
<!-- NOTE: template for group title icons -->
<ng-template #groupComplianceIcon let-groupCompliance>
    <ng-container>
        <mat-icon class="ck-success" *ngIf="groupCompliance === ComplianceStatus.Compliant">
            done
        </mat-icon>
        <mat-icon class="ck-warn" *ngIf="groupCompliance === ComplianceStatus.Warning">warning
        </mat-icon>
        <mat-icon class="ck-critical" *ngIf=" groupCompliance === ComplianceStatus.NonCompliant">
            close</mat-icon>
    </ng-container>
</ng-template>
<!-- NOTE: template for tool tip -->
<ng-template #providedExtractedToolTip let-rule="$implicit" let-index="index" let-groupIndex="groupIndex">
    <app-phx-tool-tip id="tooltip-component-auto" [targetId]="'#ruleinfo-' + index + '' + groupIndex" position="right"
        *ngIf="rule.operation">
        <table class="info-table">
            <tr>
                <td i18n="@@complianceDocumentData.fboValue">Expected Value:</td>
                <td>{{rule.providedValue.join(', ')}}</td>
            </tr>
            <tr>
                <td i18n="@@complianceDocumentData.gorillavisionValue">Found Value:</td>
                <td>{{rule.extractedValue}}</td>
            </tr>
        </table>
    </app-phx-tool-tip>
</ng-template>
