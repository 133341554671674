import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PhxConstants } from '../../model';
import { Theme } from './models/theme.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly themeLogo$ = new BehaviorSubject<string>('');
  public readonly logo$: Observable<string> = this.themeLogo$.asObservable();

  private readonly themePrefix = `fbo-theme`;

  public setTenantTheme(tenantCode: string) {
    this.applyTheme(this.themes.find(i => i.tenantCode === tenantCode) || this.getProcomTheme());
  }

  private applyTheme(theme: Theme): void {
    this.themeLogo$.next(theme.tenantLogo);
    const docRoot = document.querySelector(':root') as HTMLElement;
    theme.attributes.forEach(attribute => {
      docRoot.style.setProperty(`--${this.themePrefix}-${attribute.variableName}`, attribute.value);
    });
  }

  private get themes(): Array<Theme> {
    return [this.getProcomTheme(), this.getHcmTheme(), this.getSymbioticTheme(), this.getSoftchoiceTheme()];
  }

  private getProcomTheme(): Theme {
    return {
      tenantCode: PhxConstants.ThemeTenant.Procom,
      tenantLogo: 'logo-flex-backoffice.png',
      attributes: [
        {
          value: '#354051',
          variableName: 'side-nav-text-hover'
        },
        {
          value: '#4F5C70',
          variableName: 'logo-background'
        },
        {
          value: '#f8b91e',
          variableName: 'accent-color'
        },
        {
          value: 'url("./../../../../assets/images/todo-list-check-procom.svg")',
          variableName: 'todo-list-check'
        },
        {
          value: 'url("./../../../../assets/timeline/status_current.svg")',
          variableName: 'timeline-current-icon'
        },
        {
          value: 'url("./../../../../assets/timeline/status_done.svg")',
          variableName: 'timeline-done-icon'
        }
      ]
    };
  }

  private getHcmTheme(): Theme {
    return {
      tenantCode: PhxConstants.ThemeTenant.HCM,
      tenantLogo: 'logo-hcm.png',
      attributes: [
        {
          value: '#fff',
          variableName: 'side-nav-text-hover'
        },
        {
          value: '#6CAB43',
          variableName: 'logo-background'
        },
        {
          value: '#6CAB43',
          variableName: 'accent-color'
        },
        {
          value: 'url("./../../../../assets/images/todo-list-check-hcm.svg")',
          variableName: 'todo-list-check'
        },
        {
          value: 'url("./../../../../assets/timeline/status_current_hcm.svg")',
          variableName: 'timeline-current-icon'
        },
        {
          value: 'url("./../../../../assets/timeline/status_done_hcm.svg")',
          variableName: 'timeline-done-icon'
        }
      ]
    };
  }

  private getSymbioticTheme(): Theme {
    return {
      tenantCode: PhxConstants.ThemeTenant.SYMBIOTIC,
      tenantLogo: 'logo-symbiotic.png',
      attributes: [
        {
          value: '#fff',
          variableName: 'side-nav-text-hover'
        },
        {
          value: '#00AEEF',
          variableName: 'logo-background'
        },
        {
          value: '#00AEEF',
          variableName: 'accent-color'
        },
        {
          value: 'url("./../../../../assets/images/todo-list-check-symbiotic.svg")',
          variableName: 'todo-list-check'
        },
        {
          value: 'url("./../../../../assets/timeline/status_current_symbiotic.svg")',
          variableName: 'timeline-current-icon'
        },
        {
          value: 'url("./../../../../assets/timeline/status_done_symbiotic.svg")',
          variableName: 'timeline-done-icon'
        }
      ]
    };
  }

  private getSoftchoiceTheme(): Theme {
    return {
      tenantCode: PhxConstants.ThemeTenant.SOFTCHOICE,
      tenantLogo: 'logo-softchoice.png',
      attributes: [
        {
          value: '#fff',
          variableName: 'side-nav-text-hover'
        },
        {
          value: '#fff',
          variableName: 'logo-background'
        },
        {
          value: '#FF5000',
          variableName: 'accent-color'
        },
        {
          value: 'url("./../../../../assets/images/todo-list-check-softchoice.svg")',
          variableName: 'todo-list-check'
        },
        {
          value: 'url("./../../../../assets/timeline/status_current_softchoice.svg")',
          variableName: 'timeline-current-icon'
        },
        {
          value: 'url("./../../../../assets/timeline/status_done_softchoice.svg")',
          variableName: 'timeline-done-icon'
        }
      ]
    };
  }
}
