import { Pipe, PipeTransform } from '@angular/core';
import { CodeValueService } from '../services/code-value.service';

@Pipe({
  name: 'CodeValue'
})
export class CodeValuePipe implements PipeTransform {

  constructor(
    private codeValueService: CodeValueService
  ) {
  }

  transform(value: number | Array<number>, groupName: string, displayField: string = 'text'): string {
    if (Array.isArray(value)) {
      const displayValues = [];
      value.forEach(id => {
        const codeValue = this.codeValueService.getCodeValue(id, groupName);
        if (codeValue && codeValue[displayField]) {
          displayValues.push(codeValue[displayField]);
        }
      });
      return displayValues.join(', ');
    } else {
      const retVal = this.codeValueService.getCodeValue(value, groupName);
      return retVal && retVal[displayField];
    }
  }
}
