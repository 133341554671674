import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { ToTypedFormGroup } from '../common/model/to-typed-form-group.model';
import { PhxFormControlLayoutType } from '../common/model';
import { ContactForm } from './models/contact-form.model';
import { slideInFromLeft } from './animations/slide-in-from-left';
import { fboAdvantages } from './constants/fbo-advantages.const';
import { fadeIn } from './animations/fade-in';
import { ApiService } from '../common';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.less'],
  animations: [slideInFromLeft, fadeIn]
})
export class HomeComponent implements OnInit {
  public readonly layoutType = PhxFormControlLayoutType.Stacked;
  public readonly currentYear: number = new Date().getFullYear();
  public readonly fboAdvantages = fboAdvantages;

  public contactForm: FormGroup<ToTypedFormGroup<ContactForm>>;
  public isShowingMobileMenu = false;
  public isSubmittingForm = false;
  public hasSubmittedTheForm = false;

  constructor(@Inject(DOCUMENT) private document: Document, private apiService: ApiService) {}

  ngOnInit() {
    this.initForm();
  }

  public submit(): void {
    this.isSubmittingForm = true;
    this.apiService.httpPostRequest('homepage/contact', this.contactForm.value).subscribe(
      () => {
        this.scrollTo('#contact-section');
        this.hasSubmittedTheForm = true;
        this.isSubmittingForm = false;
      },
      () => {
        this.isSubmittingForm = false;
      }
    );
  }

  public scrollTo(fragment: string): void {
    this.isShowingMobileMenu = false;
    this.document.querySelector(fragment)?.scrollIntoView();
  }

  private initForm(): void {
    this.contactForm = new FormGroup<ToTypedFormGroup<ContactForm>>({
      company: new FormControl(''),
      email: new FormControl('', { validators: [Validators.required, Validators.email] }),
      fullName: new FormControl('', { validators: [Validators.required] }),
      message: new FormControl(''),
      phone: new FormControl('')
    });
  }
}
