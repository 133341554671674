import { PhxDialogCommentComponent } from './../phx-dialog-comment/phx-dialog-comment.component';
import { WorkflowAction, DialogComment, DialogResultType } from './../../model/index';
import { CommonService } from '../../services/common.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, HostListener, OnChanges, SimpleChanges } from '@angular/core';

export enum ButtonBarSortDirection {
  RightToLeft = 1,
  LeftToRight = 2
}

@Component({
  selector: 'app-phx-workflow-buttons',
  templateUrl: './phx-workflow-buttons.component.html',
  styleUrls: ['./phx-workflow-buttons.component.less']
})
export class PhxWorkflowButtonsComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild(PhxDialogCommentComponent, { static: true }) declineReason: PhxDialogCommentComponent;

  @Input() disabled = false;
  @Input() valid = false;
  @Input() isButtonBar = false;
  @Input() isMobileCenter = false;
  @Input() isMobileFab = false;
  @Output() callWorkflowCommand = new EventEmitter<WorkflowAction>();


  @Input() buttonSortDirection = null; // fix me
  @Input() openDefaultDeclineDialog = true; // fix me
  // function should return empty string or 'primary' or 'danger' or 'secondary'
  @Input() getActionButtonCssClass: Function;
  @Input() workflowAvailableActions: WorkflowAction[];

  private actions: WorkflowAction[];

  workflowActionButtons: WorkflowAction[];
  workflowActionLinks: WorkflowAction[];
  workflowAction: WorkflowAction = { Name: '' } as WorkflowAction;
  applicationConstants: any;
  isFabOpen = false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (this.isFabOpen) {
      this.isFabOpen = false;
    }
  }

  constructor(private commonService: CommonService) {
    this.applicationConstants = commonService.ApplicationConstants;
  }

  ngOnInit() {

    this.repaintActionButtons();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.workflowAvailableActions) {
      this.actions = changes.workflowAvailableActions.currentValue;
      if (this.actions != null) {
        // sorting buttons in reverse to match button order
        this.workflowActionLinks = this.actions
          .filter(item => item.Name && item.Name !== '')
          .sort((a, b) => a.DisplayButtonOrder - b.DisplayButtonOrder);
        if (this.buttonSortDirection === ButtonBarSortDirection.RightToLeft) {
          this.workflowActionButtons = this.actions
            .filter((action) => action.IsActionButton === true)
            .sort((a, b) => a.DisplayButtonOrder - b.DisplayButtonOrder);
        } else {
          this.workflowActionButtons = this.actions
            .filter((action) => action.IsActionButton === true)
            .sort((a, b) => b.DisplayButtonOrder - a.DisplayButtonOrder);
        }
      } else {
        this.workflowActionLinks = [];
        this.workflowActionButtons = [];
      }
    }

    this.repaintActionButtons();
  }

  repaintActionButtons() {
    if (this.getActionButtonCssClass && this.workflowActionButtons) {
      this.workflowActionButtons.forEach(item => {
        (item as any).CssClass = this.getActionButtonCssClass(item);
      });
    }
  }

  executeWorkflowAction(action: WorkflowAction) {
    this.workflowAction = action;
    if (action.TaskRoutingDialogTypeId === this.applicationConstants.TaskRoutingDialogType.Decline) {
      this.declineReason.open();
    } else {
      this.callWorkflowCommand.emit(action);
    }
    if (this.isMobileFab && this.isFabOpen) {
      this.toggleFab();
    }
  }

  declineReasonClosed(event: DialogComment) {
    if (event.ResultType === DialogResultType.OK) {
      this.workflowAction.Comments = event.Comment;
      this.callWorkflowCommand.emit(this.workflowAction);
    }
  }

  toggleFab() {
    this.isFabOpen = !this.isFabOpen;
  }

  ngOnDestroy() {
  }
}

