import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './common/services/auth.service';
import { IframeService, PhxConstants, PhxLocalizationService } from './common';
import { AppInitService } from './app-init.service';
import { ConfigurationService } from './configuration/service/configuration.service';

@Injectable()
export class AppResolver implements Resolve<any> {

  skipFailRedirectPaths = [
    'home',
    'login',
    'accountforgot',
    'procomsso',
    'reset',
    'access-code/:token',
    'portal/:token'
  ];

  automaticRedirectIfLoggedInPaths = [
    'home',
    'login'
  ];

  constructor(
    protected appInitService: AppInitService,
    protected authService: AuthService,
    protected router: Router,
    protected iframeService: IframeService,
    protected localizationService: PhxLocalizationService,
    protected configurationService: ConfigurationService
  ) { }

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    return new Observable(observer => {
      if (this.appInitService.loaded) {
        observer.next();
        observer.complete();
        this.redirectIfLoggedIn(route);
      } else {
        this.initApp(route)
          .then(() => {
            observer.next();
            observer.complete();
            this.redirectIfLoggedIn(route);
          })
          .catch((err) => {
            if (err && err.status >= PhxConstants.HTTPResponseStatus.InternalServerError) {
              observer.next();
              observer.complete();
              this.router.navigateByUrl('/service-unavailable', { skipLocationChange: true });
            } else {
              this.authService.loadTranslationDataByBrowserCulture()
                .subscribe((res) => {
                  (window as any).PhxTranslations = res;
                  if (route && route.routeConfig && this.skipFailRedirectPaths.indexOf(route.routeConfig.path) === -1) {
                    this.router.navigateByUrl('/');
                  }
                  observer.next();
                  observer.complete();
                });
            }
          });
      }
    });
  }

  async initApp(route?: ActivatedRouteSnapshot): Promise<any> {
    await this.authService.fetchCurrentUser();

    const userContext = await this.authService.loadContext();
    const locale = this.localizationService.getLangFromCultureId(userContext.User.CultureId);

    await this.configurationService.fetchFeatureConfigurationData();
    await this.authService.initApp(locale);

    this.appInitService.loaded = true;

    return true;
  }

  private redirectIfLoggedIn(route: ActivatedRouteSnapshot) {
    if (!this.authService.isLoggedIn()) {
      this.appInitService.loaded = false;
      return;
    }
    if (route && route.routeConfig && this.automaticRedirectIfLoggedInPaths.includes(route.routeConfig.path)) {
      this.authService.redirectAfterLogin();
    }
  }

}
