import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnChanges, Input, SimpleChanges, HostBinding } from '@angular/core';
import { NavigationService } from '../common';
import { BaseComponentOnDestroy } from '../common/epics/base-component-on-destroy';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from '../common/services/google-analytics/google-analytics.service';
import { BookmarkStateService } from './bookmark-state.service';
import { Bookmark } from './models/bookmark.model';

@Component({
  selector: 'app-bookmark-side-menu',
  templateUrl: './bookmark-side-menu.component.html',
  styleUrls: ['./bookmark-side-menu.component.less']
})
export class BookmarkSideMenuComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  readonly TITLE = '-title';
  readonly DATE = '-creationDate';
  readonly ICON = '-icon';

  localization = {
    noBookmarksMessage: 'You have no bookmarks',
    editBookmarkTitleLabel: 'Edit Bookmark Title',
    updateBookmarkTitleLabel: 'Update Bookmark Title',
    deleteBookmarkLabel: 'Delete Bookmark',
    titleLabel: 'Title',
    dateLabel: 'Date',
    typeLabel: 'Type'
  };

  component = {
    items: [] as Array<Bookmark & { showEditDeleteSaveMenu?: boolean }>,
    count: 0
  };
  isUpdateBookmarkFormList = [];

  bookmarkTitle = '';
  url = ''; // url of the current page
  bookmarked = false;
  showAdd = false;
  showAddingBookmark = true;
  showEditAndDelete = false;
  showOrderOptions = false;
  firstActiveNoLonger = true;
  orderByRepeatProperty = '';
  orderByRepeatReverse = true;

  @Input() eventName;
  // Host binding used to ensure custom css only effects the scope of this component
  @HostBinding('class.BookmarkSideMenuComponent') BookmarkSideMenuComponent: boolean = true;

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private bookmarkStateService: BookmarkStateService
  ) {
    super();
  }

  ngOnInit() {
    this.getBookmarks();
    this.router.events.pipe(takeUntil(this.isDestroyed$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.bookmarked = this.isUrlBookmarked();
        this.changeFavouriteIconOutsideScope();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eventName) {
      if (this.eventName === 'AddToFavourites' || this.eventName === 'AddToFavouritesA') {
        this.showAdd = true;
        this.showEditAndDelete = false;
        this.showOrderOptions = false;
        this.bookmarkTitle = this.showAddPreparation();
      } else {
        // edit or list
        if (this.component.items.length > 0) {
          this.showAdd = false;
          this.showEditAndDelete = false;
          this.showOrderOptions = false;
        } else {
          // no bookmarks need to add one
          this.showAdd = true;
          this.bookmarkTitle = this.showAddPreparation();
        }
      }
    }
  }

  /**
   * Prepare a name for the new shortcut the user can add quickly
   */
  showAddPreparation() {
    let prependTitle = '';
    const titleFeed = this.navigationService.getPageTitleDecorator();
    if (titleFeed && titleFeed.length > 0) {
      prependTitle = titleFeed[titleFeed.length - 1].Text ?? '';
      if (titleFeed.length > 1) {
        if (!isNaN(+prependTitle) || !isNaN(parseInt(prependTitle.charAt(0), 10))) {
          // If first character is a number
          prependTitle = titleFeed[titleFeed.length - 2].Text.trim() + ' - ' + prependTitle;
        }
      }
    }
    return prependTitle;
  }

  /**
   * Order the book mark items. Defaults to order by TITLE if provided parameter isnt valid.
   * @param orderBy Field to order by
   */
  sort(orderBy: string) {
    if (this.orderByRepeatProperty === orderBy) {
      this.orderByRepeatReverse = !this.orderByRepeatReverse;
      this.component.items.reverse();
    } else {
      this.orderByRepeatProperty = orderBy;
      if (orderBy === this.ICON) {
        this.sortByType();
      } else if (orderBy === this.DATE) {
        this.sortByCreateDate();
      } else {
        // default to sort by title
        this.sortByTitle();
      }
    }
  }

  /**
   * Sort the component items by title
   */
  sortByTitle() {
    this.component.items.sort(({ title: a }, { title: b }) => {
      const titleA = a.toLowerCase();
      const titleB = b.toLowerCase();
      // sort string ascending
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0; // default return value (no sorting)
    });
  }

  /**
   * Sort the component items by creation date
   */
  sortByCreateDate() {
    this.component.items.sort(({ creationDate: a }, { creationDate: b }) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Sort the component items by type/icon
   */
  sortByType() {
    this.component.items.sort(({ icon: a }, { icon: b }) => {
      const iconA = a?.toLowerCase();
      const iconB = b?.toLowerCase();
      if (iconA < iconB) {
        return -1;
      }
      if (iconA > iconB) {
        return 1;
      }
      return 0;
    });
  }

  bookmarkAdd() {
    /** No empty string bookmarks, as it breaks ui and requires extra clicks to reset the form */
    if(!this.bookmarkTitle?.trim()?.length){
      return;
    }

    const associatedType = this.router.url.split('/')[2]; // value after 'next' in route

    // // Check to make sure that the route name doesn't already exist
    const foundPathName = this.isUrlBookmarked();

    const titleFeed = this.navigationService.getPageTitleDecorator();
    let setIcon = '';
    if (titleFeed && titleFeed.length > 0) {
      setIcon = titleFeed[0].Icon;
    }

    // // Add the Statename to be able to href to it, into component.items
    if (!foundPathName && this.bookmarkTitle && associatedType) {
      // Make subfolders, split State name at dots, base parent/folder upon first name before dot; stateRoutingName
      const newBookmarkObj = {
        pathName: this.router.url,
        statePath: '',
        description: 'No description given.',
        title: this.bookmarkTitle,
        creationDate: new Date(),
        icon: setIcon,
        associatedType
      };
      this.component.items.push(newBookmarkObj);
      this.bookmarkStateService.upsertBookmark(newBookmarkObj);
      this.bookmarked = true;
      this.changeFavouriteIconOutsideScope();
      this.showAddingBookmark = false;

      this.sendBookmarkGaEvent('add', newBookmarkObj);
    }

    this.showAdd = false;
  }

  getBookmarks() {
    this.bookmarkStateService.bookmarks$.pipe(this.takeUntilDestroyed).subscribe(
      bookmarks => {
        this.component.items = bookmarks.map(b => ({ ...b }));
        this.component.count = this.component.items.length;
        this.showAdd = true;
        this.bookmarked = this.isUrlBookmarked();
        this.changeFavouriteIconOutsideScope();
      },
      err => {
        console.error('getBookmarkByUser', err);
      }
    );
  }

  bookmarkUpdate(item: Bookmark): void {
    const title = item.tempValue;
    const description = 'No description given.';
    const indexOfBookmark = this.component.items.findIndex(i => i.pathName === item.pathName);
    const foundPathName = indexOfBookmark > -1;

    if (foundPathName && title) {
      this.component.items[indexOfBookmark].description = description;
      this.component.items[indexOfBookmark].title = title;
      this.bookmarkStateService.upsertBookmark(this.component.items[indexOfBookmark]);
      // close update screen
      const updateBookmarkIndex = this.isUpdateBookmarkFormList.indexOf(item.pathName);
      if (updateBookmarkIndex > -1) {
        this.isUpdateBookmarkFormList.splice(updateBookmarkIndex, 1);
      }
    }
  }

  updateBookmarkPrecursor(item) {
    const isFound = this.isUpdateBookmarkFormList.includes(item.pathName);
    if (!isFound) {
      this.isUpdateBookmarkFormList.push(item.pathName);
    }
  }

  async bookmarkDelete(item: Bookmark): Promise<void> {
    // close update screen
    const updateBookmarkIndex = this.isUpdateBookmarkFormList.indexOf(item.pathName);
    if (updateBookmarkIndex > -1) {
      this.isUpdateBookmarkFormList.splice(updateBookmarkIndex, 1);
    }
    const willHaveRemovedTheLastElement = this.component.count === 1;
    await this.bookmarkStateService.deleteBookmark(item);
    this.sendBookmarkGaEvent('delete', item);
    if (willHaveRemovedTheLastElement) {
      this.showEditAndDelete = false;
      this.showOrderOptions = false;
      this.bookmarked = false;
    }
  }

  isFoundPathNameInUpdateList(item) {
    return this.isUpdateBookmarkFormList.includes(item.pathName);
  }

  /**
   * Change the star icon inside of sidenav component
   */
  changeFavouriteIconOutsideScope() {
    // search by class 'shortcut-add-bookmark', and replace the innerhtml from 'star_border' to 'star'
    const shortcutAddBookmark = document.getElementsByClassName('shortcut-add-bookmark');
    if (shortcutAddBookmark) {
      const newVal = this.bookmarked ? 'star' : 'star_border';
      Array.from(shortcutAddBookmark).forEach(el => (el.innerHTML = newVal));
    }
  }

  isUrlBookmarked(): boolean {
    return this.component.items.some(i => i.pathName === this.router.url);
  }

  /**
   * Redirect page to url
   * @param url Page to navigate to
   */
  redirection(url: string): void {
    const bookmark = this.component.items.find(f => f?.pathName === url);
    if (bookmark) {
      this.sendBookmarkGaEvent('navigate', bookmark);
    }

    this.router.navigateByUrl(url);
  }

  private sendBookmarkGaEvent(action: string, bookmark: { pathName: string; title: string }): void {
    this.googleAnalyticsService.sendClickData({
      feature: 'Bookmarks',
      action,
      type: `${bookmark?.pathName || 'No URL'} - ${bookmark?.title || 'No title'}`
    });
  }
}
