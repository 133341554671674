import { Component, Input, OnInit } from '@angular/core';
import { PhxConstants, CommonService, CodeValueGroups } from 'src/app/common';
import { GlobalSearchService } from '../../../services/global-search-service';
import { GlobalSearchUtil } from '../../../util/GlobalSearchUtil';

@Component({
  selector: 'app-global-search-suggestion-transaction',
  templateUrl: './global-search-suggestion-transaction.component.html',
  styleUrls: ['./global-search-suggestion-transaction.component.less']
})
export class GlobalSearchSuggestionTransactionComponent implements OnInit {
  public phxConstants: typeof PhxConstants = null;
  @Input() transactionData;
  codeValueGroups: typeof CodeValueGroups;

  constructor(private globalSearchService: GlobalSearchService, private commonService: CommonService, public gsUtil: GlobalSearchUtil) {
    this.phxConstants = PhxConstants;
    this.codeValueGroups = this.commonService.CodeValueGroups;
  }

  ngOnInit() {}

  onTransactionSuggestionClick(item) {
    this.globalSearchService.onTransactionSuggestionClick(item);
  }
}
