export const CodeValueGroups = {
    // ===============	app	==================
    Culture: 'app.CodeCulture',
    EntityType: 'app.CodeEntityType',
    FunctionalOperation: 'app.CodeFunctionalOperation',
    FunctionalRole: 'app.CodeFunctionalRole',
    FunctionalService: 'app.CodeFunctionalService',
    PaymentMethodType: 'payment.CodePaymentMethodType',
    RouteType: 'app.CodeRouteType',
    DocumentType: 'app.CodeDocumentType',
    // ===============	audit	==================
    AuditOperationChangeStatus: 'audit.CodeAuditOperationChangeStatus',
    // ===============	access	==================
    AccessSubscriptionStatus: 'access.CodeAccessSubscriptionStatus',
    AccessSubscriptionType: 'access.CodeAccessSubscriptionType',
    AccessSubscriptionRestrictionType: 'access.CodeAccessSubscriptionRestrictionType',
    ResponsibilityType: 'access.CodeResponsibilityType',
    // ===============	commission	==================
    CommissionRateHeaderStatus: 'commission.CodeCommissionRateHeaderStatus',
    CommissionRateRestrictionType: 'commission.CodeCommissionRateRestrictionType',
    CommissionRateType: 'commission.CodeCommissionRateType',
    CommissionRateVersionStatus: 'commission.CodeCommissionRateVersionStatus',
    CommissionRole: 'commission.CodeCommissionRole',
    ScheduledChangeRateApplication: 'commission.CodeScheduledChangeRateApplication',
    CommissionTransactionStatus: 'commission.CodeCommissionTransactionStatus',
    CommissionTransactionType: 'commission.CodeCommissionTransactionType',
    CommissionAdjustmentHeaderType: 'commission.CodeCommissionAdjustmentHeaderType',
    CommissionAdjustmentDetailType: 'commission.CodeCommissionAdjustmentDetailType',
    CommissionAdjustmentHeaderStatus: 'commission.CodeCommissionAdjustmentHeaderStatus',
    CommissionStructureType: 'commission.CodeCommissionStructureType',
    // ===============	compliance	==================
    ComplianceDocumentRuleAreaType: 'compliance.CodeComplianceDocumentRuleAreaType',
    ComplianceDocumentRuleEntityType: 'compliance.CodeComplianceDocumentRuleEntityType',
    ComplianceDocumentRuleExpiryType: 'compliance.CodeComplianceDocumentRuleExpiryType',
    ComplianceDocumentRuleRequiredSituationType: 'compliance.CodeComplianceDocumentRuleRequiredSituationType',
    ComplianceDocumentRuleRequiredType: 'compliance.CodeComplianceDocumentRuleRequiredType',
    ComplianceDocumentRuleRestrictionType: 'compliance.CodeComplianceDocumentRuleRestrictionType',
    ComplianceDocumentRuleStatus: 'compliance.CodeComplianceDocumentRuleStatus',
    ComplianceDocumentStatus: 'compliance.CodeComplianceDocumentStatus',
    UserDefinedCodeComplianceDocumentType: 'compliance.UserDefinedCodeComplianceDocumentType',
    UserDefinedCodeComplianceDocumentTypeStatus: 'compliance.CodeUserDefinedCodeComplianceDocumentTypeStatus',
    ComplianceDocumentRuleProfileVisibilityType: 'compliance.CodeComplianceDocumentRuleProfileVisibilityType',
    ComplianceTemplateRestrictionType: 'compliance.CodeComplianceTemplateRestrictionType',
    ComplianceDocumentRuleRestrictionFieldType: 'compliance.CodeComplianceDocumentRuleRestrictionFieldType',
    // ===============	etl	==================
    VmsImportedRecordType: 'etl.CodeVmsImportedRecordType',
    VmsUnitedStatesSourceDeductionImportedRecordType: 'etl.CodeVmsUnitedStatesSourceDeductionImportedRecordType',
    VmsDiscountImportedRecordType: 'etl.CodeVmsDiscountImportedRecordType',
    VmsExpenseImportedRecordType: 'etl.CodeVmsExpenseImportedRecordType',
    VmsCommissionImportedRecordType: 'etl.CodeVmsCommissionImportedRecordType',
    VmsFixedPriceImportedRecordType: 'etl.CodeVmsFixedPriceImportedRecordType',
    AtsSource: 'etl.CodeAtsSource',
    VmsDocumentStatus: 'etl.CodeVmsDocumentStatus',
    // ===============	geo	==================
    Country: 'geo.CodeCountry',
    Currency: 'geo.CodeCurrency',
    Subdivision: 'geo.CodeSubdivision',
    // ===============	gl	==================
    FinancialTransactionType: 'gl.CodeFinancialTransactionType',
    // ===============	inv	==================
    TransactionHeaderReversalReason: 'inv.CodeTransactionHeaderReversalReason',
    InvoiceStatus: 'inv.CodeInvoiceStatus',
    InvoiceVersionDocumentType: 'inv.CodeInvoiceVersionDocumentType',
    InvoiceTransactionDocumentStatus: 'inv.CodeInvoiceTransactionDocumentStatus',
    InvoiceRecipientType: 'inv.CodeInvoiceRecipientType',
    // ===============	org	==================
    PaysideDeductionHeaderStatus: 'org.CodePaySideDeductionHeaderStatus',
    PaysideDeductionVersionStatus: 'org.CodePaySideDeductionVersionStatus',
    RebateHeaderStatus: 'org.CodeRebateHeaderStatus',
    RebateVersionStatus: 'org.CodeRebateVersionStatus',
    VmsFeeHeaderStatus: 'org.CodeVmsFeeHeaderStatus',
    VmsFeeVersionStatus: 'org.CodeVmsFeeVersionStatus',

    OrganizationType: 'org.CodeOrganizationType',
    IndustryType: 'org.CodeIndustryType',
    LineOfBusiness: 'org.CodeLineOfBusiness',
    OrganizationRoleStatusType: 'org.CodeOrganizationRoleStatusType',
    OrganizationRoleType: 'org.CodeOrganizationRoleType',
    OrganizationBankSignature: 'org.CodeOrganizationBankSignature',
    OrganizationBankStatus: 'org.CodeOrganizationBankStatus',
    ClientSalesTaxDefault: 'org.CodeClientSalesTaxDefault',
    CodeInvoiceReleaseType: 'org.CodeInvoiceReleasePresentationType',
    RebateType: 'org.CodeRebateType',
    SectorType: 'org.CodeSectorType',
    VMSType: 'org.CodeVMSType',
    OrganizationStatus: 'org.CodeOrganizationStatus',
    OrganizationClientRoleAlternateBillStatus: 'org.CodeOrganizationClientRoleAlternateBillStatus',
    OrganizationSubVendorRoleRestrictionType: 'org.CodeOrganizationSubVendorRoleRestrictionType',
    OrganizationSubVendorRoleDiversityCertificateType: 'org.CodeOrganizationSubVendorRoleDiversityCertificateType',

    BranchPhoneType: 'org.CodeBranchPhoneType',
    // ===============	payment	==================
    PayeeType: 'payment.CodePayeeType',
    PaymentReceiverType: 'payment.CodePaymentReceiverType',
    PaymentReleaseBatchStatus: 'payment.CodePaymentReleaseBatchStatus',
    PaymentStatus: 'payment.CodePaymentStatus',
    PaymentVersionType: 'payment.CodePaymentVersionType',
    WireTransferBankType: 'payment.CodeWireTransferBankType',
    // ===============	payroll	==================
    TaxVersionStatus: 'payroll.CodeTaxVersionStatus',
    SourceDeductionType: 'payroll.CodeSourceDeductionType',
    PaymentOtherEarningType: 'payroll.CodePaymentOtherEarningType',
    WorkerCompensationStatus: 'payroll.CodeWorkerCompensationStatus',
    RemittanceTransactionBatchStatus: 'payroll.CodeRemittanceTransactionBatchStatus',
    RemittanceType: 'payroll.CodeRemittanceType',
    RemittanceStatus: 'payroll.CodeRemittanceStatus',
    WcbSubdivisionVersionStatus: 'payroll.CodeWCBSubdivisionVersionStatus',

    // ===============	po	==================
    WorkOrderPurchaseOrderLineStatus: 'po.CodeWorkOrderPurchaseOrderLineStatus',
    PurchaseOrderStatus: 'po.CodePurchaseOrderStatus',
    PurchaseOrderInvoiceRestriction: 'po.CodeInvoiceRestriction',
    PurchaseOrderDepletedOptions: 'po.CodeDepletionOption',
    PurchaseOrderDepletedGroups: 'po.CodeDepletionGroup',
    PurchaseOrderDepletedActions: 'po.CodeDepletedAction',
    // ===============	report	==================
    YesNo: 'report.CodeYesNo',
    ReportColumnType: 'report.CodeReportColumnType',
    ReportType: 'report.CodeReportType',
    // ===============	timesheet	==================
    TimeSheetStatus: 'timesheet.CodeTimeSheetStatus',
    TimeSheetType: 'timesheet.CodeTimeSheetType',
    TimesheetReversalReason: 'timesheet.CodeTimeSheetReversalReason',
    // ===============	trn	==================
    TransactionHeaderStatus: 'trn.CodeTransactionHeaderStatus',
    TransactionCategory: 'trn.CodeTransactionCategory',
    TransactionType: 'trn.CodeTransactionType',
    ARStatus: 'trn.CodeARStatus',
    PaymentTransactionStatus: 'trn.CodePaymentTransactionStatus',
    AdvanceStatuses: 'trn.CodeAdvanceStatus',
    EarningsAndDeductionsType: 'trn.CodeEarningsAndDeductionsType',
    GarnisheeStatuses: 'trn.CodeGarnisheeStatus',
    RemittanceTransactionStatus: 'trn.CodeRemittanceTransactionStatus',
    BillingTransactionStatus: 'trn.CodeBillingTransactionStatus',
    // ===============	usr	==================
    PersonTitle: 'usr.CodePersonTitle',
    DentalBenefit: 'usr.CodeDentalBenefit',
    ProfileAddressType: 'usr.CodeProfileAddressType',
    ProfileGroup: 'usr.CodeProfileGroup',
    ProfilePhoneType: 'usr.CodeProfilePhoneType',
    ProfilePrimaryStatus: 'usr.CodeProfilePrimaryStatus',
    ProfileStatus: 'usr.CodeProfileStatus',
    ProfileType: 'usr.CodeProfileType',
    UserStatus: 'usr.CodeUserStatus',
    ProfileWorkerBenefitType: 'usr.CodeProfileWorkerBenefitType',
    InternalTeamRestrictionType: 'usr.CodeInternalTeamRestrictionType',
    InternalTeamStatus: 'usr.CodeInternalTeamStatus',
    WorkerEligibilityType: 'usr.CodeWorkerEligibility',
    TemporaryForeignPermitType: 'usr.CodeTemporaryForeignPermitType',
    IECCategoryStudentType: 'usr.CodeIECCategoryStudentType',
    EligibilityForWorkPermitType: 'usr.CodeEligibilityForWorkPermit',
    WorkPermitIssuedCountry: 'usr.CodeWorkPermitIssuedCountry',
    WorkPermitType: 'usr.CodeWorkPermitType',
    CodeReassignmentStatus: 'CodeReassignmentStatus',
    CodeReassignmentType: 'CodeReassignmentType',
    // ===============	workflow	==================
    AssigneeType: 'workflow.CodeAssigneeType',
    TaskResult: 'workflow.CodeTaskResult',
    TaskStatus: 'workflow.CodeTaskStatus',
    StateAction: 'workflow.CodeStateAction',
    // ===============	workorder	==================
    BillingFrequency: 'workorder.CodeBillingFrequency',
    BillingInvoiceTemplate: 'workorder.CodeBillingInvoiceTemplate',
    BillingInvoiceTerms: 'workorder.CodeBillingInvoiceTerms',
    InvoiceType: 'workorder.CodeInvoiceType',
    DeliveryMethod: 'workorder.CodeDeliveryMethod',
    FileExportFormat: 'workorder.CodeFileExportFormat',
    PaymentReleaseSchedule: 'workorder.CodePaymentReleaseSchedule',
    PaymentInvoiceTemplate: 'workorder.CodePaymentInvoiceTemplate',
    PaymentInvoiceTerms: 'workorder.CodePaymentInvoiceTerms',
    PositionTitle: 'workorder.CodePositionTitle',
    RateType: 'workorder.CodeRateType',
    RateUnit: 'workorder.CodeRateUnit',
    RecipientType: 'workorder.CodeRecipientType',
    TimeSheetCycle: 'workorder.CodeTimeSheetCycle',
    TerminationReason: 'workorder.CodeTerminationReason',
    AssignmentStatus: 'workorder.CodeAssignmentStatus',
    WorkOrderStatus: 'workorder.CodeWorkOrderStatus',
    WorkOrderVersionStatus: 'workorder.CodeWorkOrderVersionStatus',
    WorkOrderOffboardingTask: 'workorder.CodeWorkOrderOffboardingTask',
    WorkOrderOffboardingTaskStatus: 'workorder.CodeWorkOrderOffboardingTaskStatus',
    Worksite: 'workorder.CodeWorksite',
    InternalOrganizationDefinition1: 'workorder.CodeInternalOrganizationDefinition1',
    InternalOrganizationDefinition2: 'workorder.CodeInternalOrganizationDefinition2',
    InternalOrganizationDefinition3: 'workorder.CodeInternalOrganizationDefinition3',
    InternalOrganizationDefinition4: 'workorder.CodeInternalOrganizationDefinition4',
    InternalOrganizationDefinition5: 'workorder.CodeInternalOrganizationDefinition5',
    TimeSheetMethodology: 'workorder.CodeTimeSheetMethodology',
    TimeSheetApprovalFlow: 'workorder.CodeTimeSheetApprovalFlow',
    BillingInvoicePresentationStyle: 'workorder.CodeBillingInvoicePresentationStyle',
    BillingConsolidationType: 'workorder.CodeBillingConsolidationType',
    ExpenseMethodology: 'workorder.CodeExpenseMethodology',
    TerminationReasonType: 'workorder.CodeTerminationType',
    // =============  billing document ===================
    ApprovalSequenceStatus: 'billingdocument.CodeApprovalSequenceStatus',
    ApprovalSequenceType: 'billingdocument.CodeApprovalSequenceType',
    ApprovalStatus: 'billingdocument.CodeApprovalStatus',
    ApproversGroupType: 'billingdocument.CodeApproversGroupType',
    BillingTransactionGenerationMethod: 'billingdocument.CodeBillingTransactionGenerationMethod',
    // ==================  expense    ===================
    ExpenseClaimStatus: 'expense.CodeExpenseClaimStatus',
    ExpenseCategoryFieldType: 'expense.CodeExpenseCategoryFieldType',
    TemplateStatus: 'template.CodeTemplateStatus',
    // ==================  dbo    ===================
    ESignedStatus: 'dbo.CodeESignedStatus',
    // ==================== permanent placement ===========================
    PermanentPlacementStatus: 'placement.CodePermanentPlacementStatus',
    PermanentPlacementVersionStatus: 'placement.CodePermanentPlacementVersionStatus',
    // ==================== workorder job ===========================
    JobCategory: 'workorder.CodeJobCategory',
    JobFunction: 'workorder.CodeJobFunction',
    JobSkill: 'workorder.CodeJobSkill',

    // ========== onboarding package ================
    CodeOnboardingPackageStatus: 'CodeOnboardingPackageStatus'
};
