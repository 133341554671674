import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, Validator } from '@angular/forms';
import { DxDateBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'app-phx-date-box',
  templateUrl: './phx-date-box.component.html',
  styleUrls: ['./phx-date-box.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhxDateBoxComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit, OnDestroy {
  @Input() type: 'date' | 'time' | 'datetime' = 'date'; // 'date' | 'time' | 'datetime'
  @Input() min: any; // Date|Number|String
  @Input() max: any; // Date|Number|String
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() showClearButton = false;
  @Input() width: any = 'auto'; // "55px", "80%", "auto", "inherit"
  @Input() displayFormat: string;
  @Input() maxZoomLevel: string;
  @Input() minZoomLevel: string;
  @Input() interval: number;
  @Input() pickerType: string; // calender|list|native|rollers, default: calender, native (mobile)
  @Input() placeholder: string;
  @Input() allowUserInput = true;
  @Input() openOnFieldClick = true;
  @Input() resetInvalidValueOnOpen = true;
  @Input() value: any = null;

  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() opened: EventEmitter<any> = new EventEmitter<any>();

  calendarOptions: any;

  validPickerTypes = ['calendar', 'list', 'native', 'rollers'];
  private isOpen = false;
  private dxDateBox: DxDateBoxComponent;
  private isDestroyed = false;

  constructor(@Self() @Optional() public ngControl: NgControl, private cdr: ChangeDetectorRef) {
    this.calendarOptions = { minZoomLevel: this.minZoomLevel, maxZoomLevel: this.maxZoomLevel };

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    if (this.pickerType && !this.validPickerTypes.includes(this.pickerType)) {
      throw new Error('Invalid initialization. "pickerType" is invalid.');
    }
    this.calendarOptions = { minZoomLevel: this.minZoomLevel, maxZoomLevel: this.maxZoomLevel };
  }

  @ViewChild(DxDateBoxComponent)
  set content(content: DxDateBoxComponent) {
    this.dxDateBox = content;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  ngAfterViewInit(): void {
    const isValid = !this.dxDateBox || this.dxDateBox.isValid;
    this.isValidChange.emit(isValid);
  }

  writeValue(value: any) {
    this.value = value;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  onChange = _ => {
  }

  onTouched = () => {
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  onKeyUp(event: { event: KeyboardEvent }) {
    // open the datepicker dropdown if the focus was given to this element by a tab key navigation
    if (!this.isOpen && event && event.event && event.event.code === 'Tab') {
      this.dxDateBox.instance.open();
    }
  }

  onValueChangedHandler(event: any) {
    this.value = event.value;
    if (event.event) {
      this.onChange(this.value);
    }
    this.onTouched();
    this.valueChanged.emit(event.value);
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  closedHandler(event: any) {
    this.closed.emit(event);
    this.isOpen = false;
  }

  openedHandler(event: any) {
    if (this.resetInvalidValueOnOpen && !this.dxDateBox.isValid) {
      this.dxDateBox.instance.reset();
    }
    this.opened.emit(event);
    this.isOpen = true;
  }

  validate(c: UntypedFormControl) {
    return !this.dxDateBox || this.dxDateBox.isValid ? null : {
      phxDateBox: {
        valid: false
      }
    };
  }

  isValidChangeHandler(event: any) {
    this.isValidChange.emit(event);
    if (this.ngControl != null) {
      this.ngControl.control.updateValueAndValidity({ emitEvent: false });
    }
  }
}
