<ng-template #itemTemplate>
    <div class="modal-content">
        <div *ngIf="title?.length || showChevronButton || showCloseButton || showHeader" class="modal-header">
            <button type="button" class="close back" aria-label="Back" (click)="hide()" *ngIf="showChevronButton">
                <i class="material-icons">chevron_left</i>
            </button>
            <h4 *ngIf="title?.length || showHeader" [ngClass]="{ 'display-in-mobile' : enableSearchFeature && !isSearchVisible, 'hide-in-mobile' : enableSearchFeature && isSearchVisible }"
                class="modal-title">
                <ng-container *ngIf="title?.length">{{title}}</ng-container>
                <ng-container *ngIf="!title?.length">
                    <ng-content select="[custom-title]"></ng-content>
                </ng-container>
            </h4>
            <div *ngIf="enableSearchFeature"
                class="dx-search"
                [ngClass]="{ 'display-in-mobile' : enableSearchFeature && isSearchVisible, 'hide-in-mobile' : enableSearchFeature && !isSearchVisible }">
                <dx-text-box valueChangeEvent="keyup" mode="search" (onValueChanged)="filterList($event.value)">
                </dx-text-box>
            </div>
            <button type="button" class="close" aria-label="Close" (click)="hide()"
            [ngClass]="{ 'display-in-mobile' : !enableSearchFeature, 'hide-in-mobile' : enableSearchFeature }"
            *ngIf="showCloseButton">
                <i class="material-icons">close</i>
            </button>
            <button type="button" class="close search" (click)="isSearchVisible = !isSearchVisible"
            [ngClass]="{ 'display-in-mobile' : enableSearchFeature, 'hide-in-mobile' : !enableSearchFeature }"
             *ngIf="enableSearchFeature">
                <i class="material-icons"> {{ isSearchVisible? 'close' : 'search' }} </i>
            </button>
        </div>
        <div [class.no-padding]="noMessagePadding">
            <ng-container *ngTemplateOutlet="messageTemplate"></ng-container>
        </div>
        <div class="modal-body" [class.no-padding]="noBodyPadding">
            <ng-content></ng-content>
        </div>
        <div class="modal-footer" *ngIf="buttons && buttons.length">
            <app-phx-responsive-buttons [buttons]="buttons" [placedOnModal]="true"></app-phx-responsive-buttons>
        </div>
    </div>
</ng-template>

<ng-template #messageTemplate>
	<ng-container *ngFor="let message of messages">
		<div [class]="'alert alert-' + message.messageType" *ngIf="message.showMessage()">
			<div class="row2">
				<div class="col-md-12" style="padding:0;">
					<span class="banner-box">
						<i class="alert-icon material-icons">info</i>
					</span>
					<div class="banner-box"> 
						<span [innerHTML]="message.message"></span> 
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>
