import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import { filter } from 'lodash';
import { PageTitleDetail } from './../model/page-title-detail';
import { EventService } from './event.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ReportPageTitle } from '../../report-azsearch/model/report-page-title.model';
import { addFlexBackofficeSuffix } from '../utility/add-flexbackoffice-suffix.util';

@Injectable()
export class NavigationService implements OnDestroy {
  @Output() pageTitleDecoratorsChanged: EventEmitter<PageTitleDetail[]> = new EventEmitter<PageTitleDetail[]>();
  private pageTitleDecorators: PageTitleDetail[];
  private broadcastEventLogoutSubscription: Subscription;
  private topNavState$ = new Subject<boolean>();
  private sideNavState$ = new Subject<boolean>();
  private breadcrumbState$ = new BehaviorSubject<boolean>(true);
  private removePaddingMarginState$ = new Subject<boolean>();

  constructor(private eventSvc: EventService) {
    this.broadcastEventLogoutSubscription = this.eventSvc.subscribe('broadcastEvent:logout', () => {
      this.updatePageTitleDecorators([]);
    });
  }

  ngOnDestroy(): void {
    if (this.broadcastEventLogoutSubscription) {
      this.broadcastEventLogoutSubscription.unsubscribe();
    }
  }

  public setTitle(reportPageTitle: ReportPageTitle): void;
  public setTitle(key: string, extraTexts?: Array<string | number>): void;

  public setTitle(payload: string | ReportPageTitle, extraTexts: Array<string | number> = []): void {
    let title = '';

    if (typeof payload === 'string') {
      title = this.setTitleFromTitleKey(payload, extraTexts);
    } else {
      title = payload.text;
      this.updatePageTitleDecorators(payload.details);
    }

    (window as any).document.title = addFlexBackofficeSuffix(title);
  }

  public updateTopNavState(newState: boolean) {
    this.topNavState$.next(newState);
  }

  public topNavChange$(): Observable<boolean> {
    return this.topNavState$.asObservable();
  }

  public updateSideNavState(newState: boolean) {
    this.sideNavState$.next(newState);
  }

  public sideNavChange$(): Observable<boolean> {
    return this.sideNavState$.asObservable();
  }

  public disableBreadcrumbs(): void {
    this.updateBreadCrumbState(false);
  }

  public updateBreadCrumbState(newState: boolean) {
    this.breadcrumbState$.next(newState);
  }

  public breadCrumbChange$(): Observable<boolean> {
    return this.breadcrumbState$.asObservable();
  }

  public updateRemovePaddingMarginState(newState: boolean) {
    this.removePaddingMarginState$.next(newState);
  }

  public removePaddingMarginChange$(): Observable<boolean> {
    return this.removePaddingMarginState$.asObservable();
  }

  getPageTitleDecorator() {
    return this.pageTitleDecorators;
  }

  private updatePageTitleDecorators(value: PageTitleDetail[]) {
    this.pageTitleDecorators = value;
    this.pageTitleDecoratorsChanged.emit(this.pageTitleDecorators);
  }

  private setTitleFromTitleKey(key: string, extraTexts: Array<string | number> = []): string {
    let title = '';
    const pages = (window as any).PhoenixPageTitles;
    const search = filter(pages, { Key: (key || '').toLowerCase() });
    if (search.length) {
      const page = search[0];
      const decorators: PageTitleDetail[] = page.Decorators || [];

      decorators.forEach(item => {
        if (item.ShowText === true) {
          if (title !== '') {
            title += ' - ';
          }
          title += item.Text;
        }
      });

      const extraDecorators: PageTitleDetail[] = extraTexts.map(text => ({ Text: text?.toString(), ShowText: true, ShowIcon: false }));

      this.updatePageTitleDecorators(decorators.concat(extraDecorators));
    } else {
      // eslint-disable-next-line no-console
      console.error('Page with key "' + key + '" not found');
    }
    return title;
  }
}
