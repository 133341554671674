<div class="gs-bar-container d-flex">
  <div class="gs-module-selection">
    <dx-select-box [items]="availableOptions" displayExpr="display"
                   (onValueChanged)="setSearchTypeToSearchOn($event.value.searchType)" [value]="selectedOption" class="option-select">
    </dx-select-box>
  </div>
  <div class="gs-search-bar">
    <div class="search-input-container">
      <mat-form-field class="full-width">
        <div class="input-group">
          <input #searchInputElement matInput [formControl]="searchControl" (keyup.enter)="search()" autocomplete="off"
          placeholder="Search name, email, ID..." (keyup.escape)="showSearchSuggestions = false"
          (focusin)="showSearchSuggestions = true" (focusout)="hideSuggestionsList()" class="global-search-input">
          <span class="input-group-addon search-icon-button" (click)="search()">
            <i class="material-icons-outlined">search</i>
          </span>
        </div>
      </mat-form-field>
      <div class="clear-button">
        <button mat-icon-button *ngIf="searchControl.value"
                (click)="searchControl.setValue('')">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <section *ngIf="showSearchSuggestions" class="gs-search-suggestions mat-elevation-z4">
      <ng-container *ngIf="data && data.length">
        <div class="search-all-suggestion d-flex align-items-center" (click)="search()">
          <mat-icon class="icon-left">search</mat-icon>
          <span class="d-inline-block">{{searchControl.value}} - search in
          <ng-container *ngIf="selectedOption && selectedOption.display">
            {{selectedOption.display | lowercase}}(s)
          </ng-container>
          </span>
        </div>
        <ng-container [ngSwitch]="searchTypeOnActiveStore">
          <app-global-search-suggestion-assignment [assignmentData]="data"
                                                   *ngSwitchCase="azSearchStoreExtensionType.WorkOrder">
          </app-global-search-suggestion-assignment>
          <app-global-search-suggestion-transaction [transactionData]="data"
                                                    *ngSwitchCase="azSearchStoreExtensionType.Transaction">
          </app-global-search-suggestion-transaction>
          <app-global-search-suggestion-payment [paymentData]="data"
                                                *ngSwitchCase="azSearchStoreExtensionType.Payment">
          </app-global-search-suggestion-payment>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!data || (data && !data.length)">
        <ng-container [ngSwitch]="searchTypeOnActiveStore">
          <p class="search-hint-title">You can search for the following terms:</p>
          <ng-container *ngSwitchCase="azSearchStoreExtensionType.WorkOrder">
            <ul class="search-hint">
              <li>Worker Name (legal or preferred)</li>
              <li>Worker Email</li>
              <li>Sub Vendor Name</li>
              <li>Work Order, Assignment, ATS ID</li>
              <li>PO Numbers</li>
              <li>Client name and code</li>
              <li>Client Manager name and email</li>
              <li>Timesheet Approver name</li>
            </ul>
          </ng-container>
          <ng-container *ngSwitchCase="azSearchStoreExtensionType.Transaction">
            <ul class="search-hint">
              <li>Transaction Number</li>
              <li>Transaction ID</li>
              <li>Payee or Worker Name (legal or preferred)</li>
              <li>Sub Vendor Name</li>
              <li>Work Order</li>
              <li>Cheque Number</li>
              <li>PO,Invoice Number</li>
              <li>Client, Alt Billing Client name</li>
              <li>Client email</li>
            </ul>
          </ng-container>
          <ng-container *ngSwitchCase="azSearchStoreExtensionType.Payment">
            <ul class="search-hint">
              <li>Payment, Transaction, Invoice Number</li>
              <li>Payee or Worker Name (legal or preferred)</li>
              <li>Payee or Worker Email</li>
              <li>Sub Vendor Name</li>
              <li>Work Order, Payment ID</li>
              <li>Timesheet Approver name and email</li>
            </ul>
          </ng-container>
        </ng-container>
      </ng-container>
    </section>
  </div>
</div>
