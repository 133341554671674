import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Navigation, NavigationMenuItem } from 'src/app/sidenav/model';
import { SidenavService } from 'src/app/sidenav/sidenav.service';

@Injectable({
    providedIn: 'root'
})
export class AzsearchGuard implements CanActivate {
    private accessiblePaths = new BehaviorSubject<string[]>([]);

    constructor(
        private sideNavService: SidenavService,
        private router: Router
    ) {
        this.initAccessiblePaths();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.accessiblePaths.pipe(
            filter(paths => !!paths.length),
            map(paths => {
                if (this.isAccessible(paths, state.url)) {
                    return true;
                } else {
                    this.router.navigate(['/next', 'no-access'], { replaceUrl: true });
                    return false;
                }
            })
        );
    }

    private isAccessible(accessiblePaths: string[], userPath: string): boolean {
        return accessiblePaths.filter(f => userPath.includes(f)).length > 0;
    }

    private initAccessiblePaths() {
        this.sideNavService.getNavigationData().pipe(
            filter(navigationData => !!navigationData),
            distinctUntilChanged()
        ).subscribe(navigationData => this.setAccessibleAzSearchPaths(navigationData));
    }

    private setAccessibleAzSearchPaths(navigationData: Navigation) {
        const pathList = [];
        const getPaths = (navItems: NavigationMenuItem[]) => {
            navItems.forEach(navItem => {
                /** NOTE: if user has access to billing transactions they can access transaction statistics */
                if (navItem.State && navItem.State.toLowerCase() === '/next/report-azsearch/20') {
                    pathList.push('/next/report-azsearch/35');
                }
                if (navItem.State) {
                    pathList.push(navItem.State.toLowerCase());
                }
                if (navItem.HasChildren) {
                    getPaths(navItem.Children);
                }
            });
        };

        getPaths(navigationData.NavigationItems);
        this.accessiblePaths.next(pathList.filter(f => f.includes('report-azsearch')));
    }
}
