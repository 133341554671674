import { CommonService } from './../../services/common.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { StateHistoryGroupHeader, StateHistory, PhxConstants, StateHistoryHeader, StateHistoryCurrentStatus } from '../../model';
import { CodeValueGroups } from './../../model/phx-code-value-groups';
import { WorkflowService } from './../../services/workflow.service';
import { CodeValueService } from './../../services/code-value.service';
import { PhxLocalizationService } from '../../services/phx-localization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-phx-state-history',
  templateUrl: './phx-state-history.component.html',
  styleUrls: ['./phx-state-history.component.less']
})
export class PhxStateHistoryComponent implements OnChanges {
  @Input() entityTypeId: number;
  @Input() entityId: number;

  /**
   * Current entity status(es)
   * This is used to make sure the latest history status displayed on UI is in sync with the entity status
   * It will also drive the history refresh when there's a status update
   *
   * If the entity history is versioned (ie. work order), please provide status for each version in an array of StateHistoryCurrentStatus
   */
  @Input() currentStatusId: number | Array<StateHistoryCurrentStatus>;
  @Input() fnProcessStateHistoryHeaders: (stateHistoryHeaders: Array<StateHistoryHeader>) => Array<StateHistoryHeader>;
  @Input() showNoHistoryMessage: boolean = true;
  GROUP_HISTORY = false; // first release does not support history grouping
  DEBUG = environment.allowUnfinishedFeatures === 'true'; // for now, using this
  nonVersion: boolean;

  statusCodeValueGroups: string;
  stateHistoryHeaders: Array<StateHistoryHeader>;

  phxConstant = PhxConstants;
  codeValueGroups = CodeValueGroups;

  hasHistoryToDisplay = false;

  constructor(
    private workflowService: WorkflowService,
    private codeValueService: CodeValueService,
    private localizationService: PhxLocalizationService,
    private commonService: CommonService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entityTypeId) {
      this.statusCodeValueGroups = this.getStatusCodeValueGroups(this.entityTypeId);
    }
    if (changes.entityTypeId || changes.entityId || changes.currentStatusId) {
      this.initStateHistory(this.entityTypeId, this.entityId);
    }
  }

  refresh() {
    this.initStateHistory(this.entityTypeId, this.entityId);
  }

  private initStateHistory(entityTypeId: number, entityId: number) {
    this.workflowService.getStateHistory(entityTypeId, entityId).then((stateHistoryHeader: Array<StateHistoryHeader>) => {
      if (stateHistoryHeader && stateHistoryHeader.length) {
        this.stateHistoryHeaders = stateHistoryHeader;
        if (this.fnProcessStateHistoryHeaders) {
          this.stateHistoryHeaders = this.fnProcessStateHistoryHeaders(this.stateHistoryHeaders);
        }
        this.stateHistoryHeaders.forEach((shh: StateHistoryHeader) => {
          if (shh.StateHistory.length > 0) {
            shh.StateHistoryGrouped = this.groupStateHistory(shh.StateHistory);
            this.setCurrent(shh);
            this.setFuturePath(shh);
          }
          shh.isOpen = true;
          shh.VersionDisplayText = this.getVersionDisplayText(shh);
        });
        this.nonVersion = !this.stateHistoryHeaders[0].VersionNumber && !this.stateHistoryHeaders[0].VersionName;
        this.hasHistoryToDisplay = this.stateHistoryHeaders.some(({ StateHistory }) => StateHistory.length);
      }
      this.ref.detectChanges();
    });
  }

  private setCurrent(version: StateHistoryHeader) {
    if (version) {
      const latestHistory = version.StateHistoryGrouped.find(g => !g.Future);
      const latestHistoryStatus = latestHistory ? latestHistory.EntityStatusId : null;
      if (latestHistory && latestHistory.EntityId === version.EntityId) {
        const currenStatus = this.getCurrentStatus(version.EntityId, latestHistoryStatus);
        version.CurrentStatus = currenStatus;
        if (latestHistory && latestHistoryStatus === currenStatus) {
          latestHistory.LatestCurrent = true;
        } else if (currenStatus) {
          version.StateHistoryGrouped.unshift(<StateHistoryGroupHeader>{
            LatestCurrent: true,
            EntityStatusId: currenStatus,
            StateHistory: [
              {
                EntityStatusId: currenStatus
              }
            ]
          });
        }
      } else {
        latestHistory.LatestCurrent = true;
        version.CurrentStatus = latestHistoryStatus;
      }
    }
  }

  private setFuturePath(version: StateHistoryHeader) {
    if (version && version.CurrentStatus) {
      version.StateHistoryGrouped = this.getFuturePath(version.EntityTypeId, version.CurrentStatus).concat(version.StateHistoryGrouped);
    }
  }

  private getCurrentStatus(versionEntityId: number, latestHistoryStatusId: number) {
    if (Array.isArray(this.currentStatusId)) {
      const version = this.currentStatusId.find(x => x.versionEntityId === versionEntityId);
      return (version ? version.currentStatusId : null) || latestHistoryStatusId;
    } else {
      return this.currentStatusId || latestHistoryStatusId;
    }
  }

  private getFuturePath(entityTypeId: number, statusId: number): Array<StateHistoryGroupHeader> {
    const futurePathStatusIds = this.workflowService.getPreferredEntityStatusIdPath(entityTypeId, statusId) || [];
    return futurePathStatusIds
      .map((id: number, index: number) => {
        return <StateHistoryGroupHeader>{
          Future: true,
          NextStep: index === 0,
          EntityStatusId: id,
          StateHistory: [
            {
              EntityStatusId: id,
              NextStep: index === 0
            }
          ]
        };
      })
      .reverse();
  }

  /**
   * Determine if a new group is needed.
   * If action & status are the same, we group them.
   * Alternatively, if grouping feature is turned off, every history is a group on its own.
   * @param stateHistory
   */
  private groupStateHistory(stateHistory: Array<StateHistory>): Array<StateHistoryGroupHeader> {
    const historyGrouped: Array<StateHistoryGroupHeader> = [];
    stateHistory.forEach((history: StateHistory, index: number) => {
      let curGroup: StateHistoryGroupHeader = historyGrouped.length ? historyGrouped[historyGrouped.length - 1] : null;
      history.Description = this.getHistoryDescription(history);

      if (this.needNewGroup(curGroup, history)) {
        curGroup = <StateHistoryGroupHeader>{
          EntityId: history.EntityId,
          EntityStatusId: history.EntityStatusId,
          StateActionId: history.StateActionId,
          StateHistory: []
        };
        historyGrouped.push(curGroup);
      }
      curGroup.StateHistory.push(history);
      curGroup.LastCreatedByFirstName = history.CreatedByFirstName;
      curGroup.LastCreatedByLastName = history.CreatedByLastName;
      curGroup.LastCreatedByFullName = history.CreatedByFullName;
      curGroup.LastCreatedDatetime = history.CreatedDatetime;
    });
    return historyGrouped;
  }

  /**
   * Translation for status based on entity
   * Expand this switch case to support more entities
   * @param entityTypeId
   */
  private getStatusCodeValueGroups(entityTypeId: number): string {
    switch (entityTypeId) {
      case PhxConstants.EntityType.WorkOrder:
        entityTypeId = PhxConstants.EntityType.WorkOrderVersion;
        break;
      case PhxConstants.EntityType.Contact:
        entityTypeId = PhxConstants.EntityType.UserProfile;
        break;
      case PhxConstants.EntityType.PermanentPlacement:
        entityTypeId = PhxConstants.EntityType.PermanentPlacementVersion;
        break;
      case PhxConstants.EntityType.WCBSubdivisionHeader:
        entityTypeId = PhxConstants.EntityType.WCBSubdivisionVersion;
        break;
      case PhxConstants.EntityType.ProvincialTaxHeader:
        entityTypeId = PhxConstants.EntityType.ProvincialTaxVersion;
        break;
      case PhxConstants.EntityType.FederalTaxHeader:
        entityTypeId = PhxConstants.EntityType.FederalTaxVersion;
        break;
    }
    return this.commonService.getStatusCodeValueGroups(entityTypeId);
  }

  /**
   * Get the version display text on the accordion.
   * For contact history, version number is the profile type id
   * @param versionNumber
   */
  private getVersionDisplayText(ssh: StateHistoryHeader): string {
    if (this.entityTypeId === PhxConstants.EntityType.Contact) {
      return this.codeValueService.getCodeValueText(ssh.VersionNumber, CodeValueGroups.ProfileType);
    } else if (ssh.EntityTypeId === PhxConstants.EntityType.ComplianceDocument) {
      return ssh.EntityId + '.' + ssh.HeaderTitle;
    } else if (ssh.VersionNumber == null && ssh.VersionName) {
      return ssh.VersionName;
    }
    return this.localizationService.translate('workflow.stateHistory.Version', ssh.VersionNumber);
  }

  /**
   * Determine if a new group is needed.
   * If action & status are the same, we group them.
   * Alternatively, if grouping feature is turned off, every history is a group on its own.
   * @param curGroup
   * @param history
   */
  private needNewGroup(curGroup: StateHistoryGroupHeader, history: StateHistory): boolean {
    const curStatusId = curGroup ? curGroup.EntityStatusId : null;
    const curStateActionId = curGroup ? curGroup.StateActionId : null;
    return curStatusId !== history.EntityStatusId || curStateActionId !== history.StateActionId || !this.GROUP_HISTORY;
  }

  /**
   * Get the translated description based on action
   * Default resource is in workflow.stateHistory.{actionCode}
   *
   * If entity based custom description is desired, alternate resource is in workflow.{entityTypeCode}History.{actionCode}
   * @param history
   */
  private getHistoryDescription(history: StateHistory): string {
    return this.commonService.getHistoryDescription(history, this.entityTypeId, this.DEBUG);
  }
}
