import { Store as AzSearchStore } from 'azsearchstore';
import { IFacetsConfig, ListViewConfig } from './global-search-facet';
import { AzSearchStoreExtension } from '../az-search-store-extension/az-search-store-extension';

export type PendoEvent = string;

export interface IPendoEvents {
    GoToItemFromCard?: PendoEvent;
    GoToItemFromListRow?: PendoEvent;

    SearchFromSearchBar?: PendoEvent;
    SearchFromFacet?: PendoEvent;
    SearchFromSort?: PendoEvent;
    SuggestionsLoad?: PendoEvent;

    WorkOrderChipClick?: PendoEvent;
    PaymentChipClick?: PendoEvent;
    TimeSheetChipClick?: PendoEvent;
    TransactionChipClick?: PendoEvent;
    InvoicesChipClick?: PendoEvent;
    WorkerChipClick?: PendoEvent;
    SupplierChipClick?: PendoEvent;
    ClientChipClick?: PendoEvent;
}

export const GSWorkOrderPendoEvents: IPendoEvents  = {
    GoToItemFromCard: 'GS WO Card Clicked',
    GoToItemFromListRow: 'GS WO List Row Clicked',

    SearchFromSearchBar: 'GS WO Searched From Search Bar',
    SearchFromFacet: 'GS WO Searched From Facet',
    SearchFromSort: 'GS WO Searched From Sort',
    SuggestionsLoad: 'GS WO Suggestions Load',

    ClientChipClick: 'GS WO Client Chip Clicked',
    PaymentChipClick: 'GS WO Payment Chip Clicked',
    TransactionChipClick: 'GS WO Transaction Chip Clicked',
};

export const GSTransactionPendoEvents: IPendoEvents = {
    GoToItemFromCard: 'GS TRN Card Clicked',
    GoToItemFromListRow: 'GS TRN List Row Clicked',

    SearchFromSearchBar: 'GS TRN Searched From Search Bar',
    SearchFromFacet: 'GS TRN Searched From Facet',
    SearchFromSort: 'GS TRN Searched From Sort',
    SuggestionsLoad: 'GS TRN Suggestions Load',

    WorkOrderChipClick: 'GS TRN Work Order Chip Clicked',
    PaymentChipClick: 'GS TRN Payment Chip Clicked',
    TimeSheetChipClick: 'GS TRN Time Sheet Chip Clicked',
    InvoicesChipClick: 'GS TRN Invoices Chip Clicked',
    WorkerChipClick: 'GS TRN Worker Chip Clicked',
    SupplierChipClick: 'GS TRN Supplier Chip Clicked',
    ClientChipClick: 'GS TRN Client Chip Clicked'
};

export const GSPaymentPendoEvents: IPendoEvents = {
    GoToItemFromCard: 'GS PAY Card Clicked',
    GoToItemFromListRow: 'GS PAY List Row Clicked',

    SearchFromSearchBar: 'GS PAY Searched From Search Bar',
    SearchFromFacet: 'GS PAY Searched From Facet',
    SearchFromSort: 'GS PAY Searched From Sort',
    SuggestionsLoad: 'GS PAY Suggestions Load',

    WorkOrderChipClick: 'GS PAY Work Order Chip Clicked',
    PaymentChipClick: 'GS PAY Payment Chip Clicked',
    TimeSheetChipClick: 'GS PAY Time Sheet Chip Clicked',
    InvoicesChipClick: 'GS PAY Invoices Chip Clicked',
    WorkerChipClick: 'GS PAY Worker Chip Clicked',
    SupplierChipClick: 'GS PAY Supplier Chip Clicked',
    ClientChipClick: 'GS PAY Client Chip Clicked'
};

export interface IGsConfiguration {
    config: AzSearchStore.Config;
    searchParameters: AzSearchStore.SearchParametersUpdate;
    suggestionParameters: AzSearchStore.SuggestionsParametersUpdate;
    facetsConfig: IFacetsConfig;
    listViewConfig: ListViewConfig;
    // typeDeclarations: any;
    // GsSearchType: GsIndexType;
}

export enum AzSearchStoreExtensionType {
    WorkOrder,
    Transaction,
    Payment
}

export const SearchIndexNameForClass = {
    [AzSearchStoreExtensionType.WorkOrder]: 'WorkOrder',
    [AzSearchStoreExtensionType.Transaction]: 'Transaction',
    [AzSearchStoreExtensionType.Payment]: 'Payment'
};

export interface IActiveGsAzSearchStoreExtensionInfo {
    AzSearchStoreExtension: AzSearchStoreExtension;
    Type: AzSearchStoreExtensionType;
}

export enum GlobalSearchFieldType {
    Number,
    String,
    Date,
    Collection
}
