<div class="navbar navbar-inverse" id="navbar">
  <div class="navbar-container" id="navbar-container">
    <div class="row">
      <div class="col-xs-2 col-sm-1 hidden-xxxl hidden-xxl hidden-xl hidden-lg-custom pull-left">
        <a class="menu-toggler pull-left" (click)="onSideMenuOpenChanged()">
          <span class="menu-text">Open Menu</span>
        </a>
      </div>
      <div class="col-xs-4 col-md-3 col-sm-3 padding-l-0">
          <a class="logo-container" routerLink="/next/activity-centre/to-do">
              <ng-container *ngIf="(logo$ | async) as logo">
                  <img [src]="'/assets/logos/' + logo" alt="logo" class="padding-10-v padding-10-h logo" />
              </ng-container>
          </a>
      </div>
      <div class="col-xs-2 col-md-1 col-sm-1 pull-right">
        <div class="navbar-header pull-right" role="navigation" id="leftMenu">
          <app-top-nav-menu></app-top-nav-menu>
        </div>
      </div>
      <div *ngIf="showGlobalSearch" class="col-xs-10 col-md-6 col-lg-5 col-sm-10 global-search-container">
        <app-global-search></app-global-search>
      </div>
    </div>
  </div>
</div>
