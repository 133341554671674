<div [ngClass]="{'todo-enabled-datagrid-container': todoSelectionIsEnabled}">
    <dx-data-grid
        #grid
        id="grid"
        [dataSource]="phxDataSource"
        class="gridContainer"
        [columns]="columns"
        [editing]="phxEditing"
        [summary]="{ totalItems: summary }"
        [height]="height"
        [allowColumnResizing]="true"
        [columnResizingMode]="configuration.columnHidingEnabled ? 'column' : 'widget'"
        [allowColumnReordering]="true"
        [columnHidingEnabled]="configuration.columnHidingEnabled"
        [columnAutoWidth]="true"
        [showBorders]="configuration.showBorders"
        [showColumnLines]="true"
        [rowAlternationEnabled]="configuration.rowAlternationEnabled"
        [hoverStateEnabled]="true"
        [stateStoring]="stateStoringConfig"
        [masterDetail]="masterDetailConfig"
        [export]="exportConfig"
        [noDataText]="configuration.noDataText"
        [loadPanel]="loadPanelConfig"
        [selectedRowKeys]="[]"
        (onSelectionChanged)="onSelectionChanged($event)"
        (onCellClick)="onCellClick($event)"
        (onRowClick)="onRowClick($event)"
        (onRowExpanding)="onMasterRowExpanding($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        (onRowPrepared)="onRowPrepared($event)"
        (onCellPrepared)="onCellPrepared($event)"
        (onContextMenuPreparing)="onContextMenuPreparing($event)"
        (onEditorPreparing)="onEditorPreparing($event)"
        (onContentReady)="onContentReady($event)"
        (onInitialized)="onInitialized($event)"
        [syncLookupFilterValues]="false"
        >
        <dxo-selection [mode]="configuration.selectionMode" [selectAllMode]="configuration.selectAllMode"
                    [allowSelectAll]="configuration.allowSelectAll"
                   [showCheckBoxesMode]="configuration.showCheckBoxesMode" [deferred]="configuration.deferred">
        </dxo-selection>
        <dxo-filter-row [visible]="configuration.showFilter"></dxo-filter-row>
        <dxo-header-filter [visible]="false"></dxo-header-filter>
        <dxo-search-panel [visible]="configuration.showSearch" [width]="200"
                        placeholder="{{ 'common.generic.search' | phxTranslate }}..."></dxo-search-panel>
        <dxo-group-panel [visible]="configuration.showGrouping"
                        [emptyPanelText]="'common.phxDataTable.groupByDescription' | phxTranslate"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="true" [expandMode]="'buttonClick'" [contextMenuEnabled]="true"
                    [allowCollapsing]="false"></dxo-grouping>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-scrolling [mode]="configuration.scrollMode" showScrollbar="always" [useNative]="true"></dxo-scrolling>
        <dxo-paging [pageSize]="configuration.pageSize"></dxo-paging>
        <dxo-remote-operations [sorting]="true" [paging]="true" [filtering]="true" [summary]="true"></dxo-remote-operations>
        <div *dxTemplate="let data of 'centerToolbarContent'">
            <div class="center-toolbar-content">
                <div class="text-content">
                    <ng-container  *ngIf="gridTotalCount">
                        {{ pageIndex * pageSize + 1 }} - {{ gridTotalCount < (pageIndex + 1) * pageSize ? gridTotalCount : (pageIndex + 1) * pageSize }} <span i18n="@@common.of">of</span> {{ gridTotalCount }}
                    </ng-container>
                </div>
                <div class="navigation-buttons">
            <span class="material-icons navigation-button-previous"
                [ngClass]="{'navigation-button-disabled': pageIndex === 0}" *ngIf="gridTotalCount > pageSize"
                (click)="goPreviousPage()">
            navigate_before
            </span>
                    <span class="material-icons navigation-button-next"
                        [ngClass]="{'navigation-button-disabled': pageIndex === lastPageIndex}"
                        *ngIf="gridTotalCount > pageSize" (click)="goNextPage()">
            navigate_next
            </span>
                </div>
            </div>
        </div>
        <div class="custom-toolbar-content" *dxTemplate="let data of 'toolbarContent'">
            <ng-container *ngIf="configuration.stateSavingMode !== PhxDataTableStateSavingMode.None">
                <button class="btn btn-default template-button" (click)="refreshAndSaveCurrentView()"
                        [title]="commonModuleResourceKeys.generic.refresh | phxTranslate">
                    <i class="material-icons custom-tool-button" aria-hidden="true">replay</i>
                    <span>{{commonModuleResourceKeys.generic.refresh | phxTranslate}}</span>
                </button>
                <button *ngIf="showShareBtn" id="shareButton" class="btn btn-default template-share-button"
                        [title]="commonModuleResourceKeys.phxDataTable.share | phxTranslate"
                        (click)="reportShareDialog.show()"
                        [disabled]="!rootFormGroup || currentUserProfileId !== selectedState.OwnerProfileId">
                    <i class="material-icons custom-tool-button" aria-hidden="true">share</i>
                    <span>{{commonModuleResourceKeys.phxDataTable.share | phxTranslate}}</span>
                </button>
                <button id="resetButton" class="btn btn-default template-reset-button" (click)="resetState()"
                        [title]="commonModuleResourceKeys.generic.reset | phxTranslate">
                    <i class="material-icons custom-tool-button" aria-hidden="true">replay</i>
                    <span>{{commonModuleResourceKeys.generic.reset | phxTranslate}}</span>
                </button>
                <button id="saveAsButton" *ngIf="configuration.stateSavingMode === PhxDataTableStateSavingMode.Customizable"
                        class="btn btn-default template-save-button" (click)="saveAsState()"
                        [title]="commonModuleResourceKeys.phxDataTable.saveViewBtn | phxTranslate">
                    <i class="material-icons custom-tool-button" aria-hidden="true">save</i>
                    <span>{{commonModuleResourceKeys.phxDataTable.saveViewBtn | phxTranslate}}</span>
                </button>
            </ng-container>
            <button *ngIf="hasFilter && configuration.showFilter && configuration.showClearFilterButton"
                    class="btn template-clear-filter-button" (click)="resetFilters($event)">
                <span>{{ commonModuleResourceKeys.phxDataTable.resetFilterBtn | phxTranslate }}</span>
                <i class="material-icons custom-tool-button" aria-hidden="true">filter_list</i>
            </button>
        </div>
        <div *dxTemplate="let d of 'defaultDateCellTemplate'">
            <span>{{ d.value | date: dateColumnFormat }}</span>
        </div>
    </dx-data-grid>
</div>
<div class="bottom-spacer"></div>

<div class="record-count-panel" *ngIf="configuration.showTotalCount && (totalCount || currentCount)">
  <span>
    <span class="record-count-value">{{ currentCount }}</span> {{ 'common.phxDataTable.countOfTotalCountMessagePart1' | phxTranslate }}
      <span class="record-count-value">{{ totalCount }}</span>
      {{ 'common.phxDataTable.countOfTotalCountMessagePart2' | phxTranslate }}</span
  >
</div>

<app-phx-azsearch-column-chooser [(visible)]="isColumnChooserVisible" [container]="'#grid'"
    (orderColumns)="orderColumns($event)"
    (closeChooser)="closeChooser()"
    (updateColumnVisibility)="updateColumnVisibility($event)"></app-phx-azsearch-column-chooser>

<app-phx-modal #saveModal [buttons]="saveStateModalButtons" [fullScreen]="false"
               [title]="'common.phxDataTable.saveViewDialogTitle' | phxTranslate">
    <form #addStateForm="ngForm" novalidate>
        <div class="form-group">
            <label for="name">{{ 'common.phxDataTable.viewNameLabel' | phxTranslate }} *</label>
            <dx-select-box
                    [items]="stateDetails"
                    displayExpr="Name"
                    [acceptCustomValue]="true"
                    [searchEnabled]="true"
                    required
                    [(ngModel)]="selectedState"
                    #name="ngModel"
                    maxlength="128"
                    minlength="3"
                    id="name"
                    name="name"
                    [placeholder]="'common.phxDataTable.viewNamePlaceholder' | phxTranslate"
                    (onValueChanged)="nameIsChanged = true; newStateDescription = selectedState ?  selectedState.Description : newStateDescription"
                    (onCustomItemCreating)="addCustomItem($event)"
            ></dx-select-box>
        </div>
        <div class="form-group">
            <label for="description">{{ 'common.phxDataTable.viewDescriptionLabel' | phxTranslate }}</label>
            <textarea rows="3" class="form-control" id="description" name="description"
                      [(ngModel)]="newStateDescription" maxlength="512"></textarea>
        </div>
        <div *ngIf="name.errors && name.dirty && nameIsChanged" class="validation-error">
            <div *ngIf="name.errors && name?.errors.required">
                {{ 'common.phxDataTable.viewNameRequired' | phxTranslate }}
            </div>
            <div *ngIf="name.errors && name?.errors.maxlength">
                {{ 'common.phxDataTable.viewNameMaxLength' | phxTranslate }}
            </div>
            <div *ngIf="name.errors && name?.errors.minlength">
                {{ 'common.phxDataTable.viewNameMinLength' | phxTranslate }}
            </div>
        </div>
    </form>
</app-phx-modal>

<app-report-share-dialog #reportShareDialog [inputFormGroup]="rootFormGroup"
                         (shareReport)="saveAndShareReport($event)"></app-report-share-dialog>
