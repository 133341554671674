import { ApiService, LoadingSpinnerService } from '../../common';
import { IDownloadCenterFile, IDownloadCenterFiles, IReportPermissions } from '../model/download-center';
import { merge, Observable, Subject } from 'rxjs';
import { DOWNLOAD_CENTER_FILES_REFRESH_RATE_MS } from '../config';
import { environment } from '../../../environments/environment';
import { IServerFileDownload, IServerReportPermissions, ReportExportResponse } from './i-server-response';
import { delay, distinctUntilChanged, finalize, map, repeatWhen, retryWhen, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

/**
 * Use this for all download center API needs.
 * ___
 * `DownloadCenterApiWrapper` will make all necessary conversions between frontend and backend object structures.
 */
export class DownloadCenterApiWrapper {
  downloadCenterFilesRefreshRate = DOWNLOAD_CENTER_FILES_REFRESH_RATE_MS || 6000;
  forceFileLoadObservable = new Subject<void>();
  spinnerEnabled = false;

  constructor(private apiService: ApiService,
              private http: HttpClient,
              private loadingSpinnerService: LoadingSpinnerService) {
  }

  /** Gets all downloads for the current user and updates the entries every so often to look for status changes. */
  getDownloadCenterFiles(): Observable<IDownloadCenterFiles> {
    this.showSpinner();
    return this.getDownloadCenterFilesHelper();
  }

  downloadFile(downloadCenterFile: IDownloadCenterFile) {
    return this.http.get<Blob>(`${environment.reportServiceApiEndpoint}/report/fileDownload/${downloadCenterFile.FileServiceFileId}`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  clearFile(downloadCenterFile: IDownloadCenterFile) {
    return this.apiService.httpDeleteRequest(`Report/reportExport/${downloadCenterFile.Id}/archive`, environment.reportServiceApiEndpoint, true)
      .pipe(this.forceFileLoadAfterApiCall());
  }

  clearAllFiles() {
    return this.apiService.httpDeleteRequest(`Report/reportExport/archiveAllFilesByProfile`, environment.reportServiceApiEndpoint, true)
      .pipe(this.forceFileLoadAfterApiCall());
  }

  retryFilePreparation(downloadCenterFile: IDownloadCenterFile) {
    return this.apiService.httpPostRequest(`Report/reportExport/${downloadCenterFile.Id}/retryExport`, null, environment.reportServiceApiEndpoint, true)
      .pipe(this.forceFileLoadAfterApiCall());
  }

  getReportPermissions(): Observable<IReportPermissions> {
    return this.apiService.httpGetRequest(`ReportSecurityView/GetAccessSummary`, environment.reportServiceApiEndpoint, false)
      .pipe(map((serverReportPermissions: IServerReportPermissions) => {
        return {
          IsMyDataViewable: serverReportPermissions.showMyData,
          IsMyDataExportable: serverReportPermissions.exportMyData,
          IsTeamDataViewable: serverReportPermissions.showTeamData,
          IsTeamDataExportable: serverReportPermissions.exportTeamData,
          IsAllDataViewable: serverReportPermissions.showAllCompanyData,
          IsAllDataExportable: serverReportPermissions.exportAllCompanyData,
          IsHierarchicalDataViewable: serverReportPermissions.showHierarchicalData,
          IsHierarchicalDataExportable: serverReportPermissions.exportHierarchicalData
        } as IReportPermissions;
      }));
  }

  getReportExport(reportExportId: string) {
    return this.apiService.httpGetRequest<IServerFileDownload>(`report/reportExport/${reportExportId}`, environment.reportServiceApiEndpoint, false)
      .pipe(map(response => this.getDownloadCenterFile(response)));
  }

  forceFileLoad(showSpinner = false) {
    if (showSpinner) {
      this.showSpinner();
    }
    this.forceFileLoadObservable.next();
  }

  private forceFileLoadAfterApiCall() {
    return finalize(() => {
      this.forceFileLoad(true);
    });
  }

  private showSpinner() {
    if (this.spinnerEnabled) {
      return;
    }
    this.spinnerEnabled = true;
    this.loadingSpinnerService.show();
  }

  private hideSpinner() {
    if (!this.spinnerEnabled) {
      return;
    }
    this.spinnerEnabled = false;
    this.loadingSpinnerService.hide();
  }

  private getDownloadCenterFilesHelper() {
    return this.apiService.httpGetRequest('Report/getReportExportByProfile', environment.reportServiceApiEndpoint, false)
      .pipe(
        repeatWhen(completed => {
          return merge(this.forceFileLoadObservable,
            completed.pipe(delay(this.downloadCenterFilesRefreshRate)));
        }),
        retryWhen(errors => {
          return errors.pipe(
            tap(() => this.hideSpinner()),
            delay(this.downloadCenterFilesRefreshRate));
        }),
        tap(() => this.hideSpinner()),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        map((response: ReportExportResponse) => {
          return (response || []).map((serverFile: IServerFileDownload) => {
            return this.getDownloadCenterFile(serverFile);
          });
        })
      );
  }

  private getDownloadCenterFile(serverFile: IServerFileDownload): IDownloadCenterFile {
    return {
      Id: serverFile.id,
      Filename: serverFile.fileName,
      Status: serverFile.downloadStatusId as number,
      ExportRequestedDate: new Date(serverFile.createdDateTime),
      FileSize: serverFile.fileByteSize,
      RequestedCount: serverFile.recordsRequested,
      ExportedCount: serverFile.recordsExported,
      FileServiceFileId: serverFile.fileServiceFileId
    } as IDownloadCenterFile;
  }
}
