import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewRef } from '@angular/core';
import { DialogService, PhxLocalizationService } from '../../../common';
import { OnClickStateActionOption, StateAction, StateActionButtonsOption, StateActionButtonStyle, StateActionDisplayType } from '../../../common/model';

@Component({
  selector: 'app-phx-state-action',
  templateUrl: './phx-state-action.component.html',
  styleUrls: ['./phx-state-action.component.less']
})
export class PhxStateActionComponent implements OnChanges {
  /**
   * The configuration of actions.
   * Refer to the StateAction interface for more details.
   */
  @Input() stateActions: StateAction[] = [];

  /**
   * The display type of the component.
   * Refer to the StateActionDisplayType for available options.
   */
  @Input() displayType: StateActionDisplayType = StateActionDisplayType.BUTTON;

  /**
   * Reference data that will be passed back to call back functions in componentOption
   */
  @Input() refData: any;

  executing = false;
  actionClicked: StateAction;
  stateActionDisplayType = StateActionDisplayType;

  constructor(
    private localizationService: PhxLocalizationService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.stateActions) {
        this.configStateActions();
      }
    }
  }

  isActionHidden(action: StateAction) {
    let hidden = false;
    if (action.hiddenFn) {
      hidden = action.hiddenFn(action, this.getComponentOption());
    }
    return hidden;
  }

  isActionDisabled(action: StateAction) {
    let disabled = false;
    if (action.disabledFn) {
      disabled = action.disabledFn(action, this.getComponentOption());
    }
    return disabled;
  }

  onClickAction(action: StateAction) {
    if (!this.isActionDisabled(action) && !this.isActionHidden(action)) {
      this.actionClicked = action;
      if (action.showDeclinedCommentDialog) {
        this.showDeclinedCommentDialog();
      } else {
        this.executeAction(action);
      }
    }
  }

  executeAction(action: StateAction, option?: OnClickStateActionOption) {
    if (!this.executing) {
      this.executing = true;
      action.onClick(action, this.getComponentOption(), option);
      setTimeout(() => {
        this.executing = false;
        if (!(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      }, 1000);
    }
  }

  showDeclinedCommentDialog() {
    this.dialogService.comment({
      title: this.actionClicked.displayText,
      helpBlock: this.localizationService.translate('common.phxWorkflowButtons.declineHelpblock', this.actionClicked.displayText),
      label: this.localizationService.translate('common.phxWorkflowButtons.declineReasonLabel', this.actionClicked.displayText.toLowerCase()),
      maxLength: 1024,
      saveButtonText: this.actionClicked.displayText
    },
      { size: 'md' }
    ).then(comment => {
      this.executeAction(this.actionClicked, {
        comment
      });
    }, () => {
    });
  }

  getComponentOption(): StateActionButtonsOption {
    return {
      displayType: this.displayType,
      refData: this.refData
    };
  }

  configStateActions() {
    let actions = this.stateActions || [];
    actions = actions.map((action: StateAction) => ({
      ...action,
      primaryAction: action.style === StateActionButtonStyle.PRIMARY,
      secondaryAction: action.style === StateActionButtonStyle.SECONDARY,
      dangerAction: action.style === StateActionButtonStyle.DANGER,
      warningAction: action.style === StateActionButtonStyle.WARNING
    }));
    this.stateActions = actions.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  hasStateActions() {
    return this.stateActions && this.stateActions.some((action: StateAction) => !this.isActionHidden(action));
  }
}

