import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  NgZone, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID, SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DxComponent, DxTagBoxComponent, DxTemplateHost, WatcherHelper } from 'devextreme-angular';
import { TransferState } from '@angular/platform-browser';
import { difference } from 'lodash';

@Component({
  selector: 'app-phx-tag-box',
  templateUrl: './phx-tag-box.component.html',
  styleUrls: ['./phx-tag-box.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhxTagBoxComponent)
    }
  ]
})
export class PhxTagBoxComponent extends DxComponent implements OnInit, ControlValueAccessor, AfterContentInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() items: any;
  @Input() placeholder: string;
  @Input() dataSource: any;
  @Input() displayExpr: any;
  @Input() valueExpr: any;
  @Input() itemTemplate = 'item-template';
  @Input() fieldTemplate = 'field-template';
  @Input() tagTemplate = 'tag-template';
  @Input() showSelectionControls: boolean = true;
  @Input() searchEnabled = false;
  @Input() applyValueMode = 'useButtons';
  @Input() maxDisplayedTags: number;
  @Input() disabled = false;
  @Input() value: any;
  @Input() parentValueField: string;
  @Input() selectedParentValues: any[];

  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild('tagBox', { static: true }) tagBox: DxTagBoxComponent;
  private popoverWithPopoverClasses: Element;
  private internalPopoverClasses: string[];
  private isDestroyed = false;

  phxDataSource: any;

  constructor(
    private eRef: ElementRef,
    ngZone: NgZone,
    private templateHost: DxTemplateHost,
    private _watcherHelper: WatcherHelper,
    transferState: TransferState,
    @Inject(PLATFORM_ID) platformId: any,
    private cdr: ChangeDetectorRef
  ) {
    super(eRef, ngZone, templateHost, _watcherHelper, null, null);
  }

  @Input()
  get popoverClasses() {
    return this.internalPopoverClasses || [];
  }

  set popoverClasses(classNames: string[]) {
    this.removePopoverClasses();
    this.internalPopoverClasses = classNames;
    this.addPopoverClasses();
  }

  _createInstance(element: any, options: any) {
    return this.tagBox.instance;
  }

  ngOnInit() {
    this.setPrivateDataSource();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  ngAfterViewInit() {
    if (this.instance) {
      super.ngAfterViewInit();
    }
  }

  private setPrivateDataSource() {
    if (!this.parentValueField) {
      this.phxDataSource = this.dataSource;
    } else {
      this.phxDataSource = this.getChildDropdown(this.selectedParentValues);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedParentValues || changes.parentValueField || changes.dataSource) {
      this.setPrivateDataSource();
    }
  }

  private getChildDropdown(parentValues: Array<any>) {
    if (parentValues?.length > 0) {
      const childValues = this.dataSource;
      return parentValues
        .map(parentId => childValues
          .filter(childValue => parentId === childValue[this.parentValueField])
        )
         .flatMap(x => x);
    } else {
      return [];
    }
  }

  ngAfterContentInit(): void {
    if (this.templates) {
      const itemTemplates = this.templates.filter(t => t.name === this.itemTemplate);
      if (itemTemplates && itemTemplates.length) {
        this.tagBox.itemTemplate = itemTemplates[0];
      }

      const fieldTemplates = this.templates.filter(t => t.name === this.fieldTemplate);
      if (fieldTemplates && fieldTemplates.length) {
        this.tagBox.fieldTemplate = fieldTemplates[0];
      }

      const tagTemplates = this.templates.filter(t => t.name === this.tagTemplate);
      if (tagTemplates && tagTemplates.length) {
        this.tagBox.tagTemplate = tagTemplates[0];
      }
    }
  }

  writeValue(value: any) {
    this.value = value;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  onChange = (_) => {
  }

  onTouched = () => {
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  onValueChanged(event: any) {
    event.addedValueItems = difference(event.value, this.value);
    event.removedValueItems = difference(this.value, event.value);
    if (event.addedValueItems.length > 0 || event.removedValueItems.length > 0) {
      this.value = event.value;
      this.onChange(this.value);
      this.onTouched();
      this.valueChanged.emit(event);
      if (!this.isDestroyed) {
        this.cdr.detectChanges();
      }
    }
  }

  clear() {
    this.value = null;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  addPopoverClasses() {
    if (!this.popoverClasses.length) {
      return;
    }
    const popover = this.getPopover();
    if (!popover) {
      return;
    }
    this.popoverClasses.forEach(className => {
      popover.classList.add(className);
    });
    this.popoverWithPopoverClasses = popover;
  }

  removePopoverClasses() {
    if (!this.popoverWithPopoverClasses || !this.popoverClasses || !this.popoverClasses.length) {
      return;
    }
    this.popoverClasses.forEach(className => {
      this.popoverWithPopoverClasses.classList.remove(className);
    });
    this.popoverWithPopoverClasses = null;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  private getPopover(): Element {
    const popover = document.getElementsByClassName('dx-overlay-wrapper');
    if (!popover.length) {
      return;
    }
    return popover[0];
  }
}
