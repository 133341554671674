import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonService } from '../../index';
import { groupBy } from 'lodash';
import { CommandResponse } from '../../model';

@Component({
  selector: 'app-phx-validation-messages',
  templateUrl: './phx-validation-messages.component.html',
  styleUrls: ['./phx-validation-messages.component.css']
})
export class PhxValidationMessagesComponent implements OnInit, OnChanges {
  @Input() showPropertyNames = true;
  @Input() messages: CommandResponse = null;
  validationMessageBox: { items: Array<any>, visible: boolean };

  constructor(private commonService: CommonService) {
    this.validationMessageBox = { items: [], visible: false };
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.messages) {
      const parsedMessages = (changes.messages.currentValue) ? this.parseMessages(changes.messages.currentValue || {}) : {};
      this.assignItems(parsedMessages);
    }
  }

  private parseMessages(err: CommandResponse) {
    const msg = this.commonService.parseResponseError(err);
    const grouped: any = groupBy(msg, 'PropertyName');
    Object.keys(grouped).forEach((key: string) => {
      grouped[key] = grouped[key].map(value => value.Message);
    });
    return grouped || {};
  }

  private assignItems(msgs: any) {
    const boxItems = [];
    let boxIsVisible = false;
    Object.keys(msgs).map(key => {
      const o = msgs[key];
      key = key || '';
      key = key.replace('Hide Property Name', '');
      const tempArr = key.split('.');
      const k = tempArr[tempArr.length - 1];
      boxItems.push({ propertyName: k, messages: o });
      boxIsVisible = true;
    });
    this.validationMessageBox.items = boxItems;
    this.validationMessageBox.visible = boxIsVisible;
  }
}
