import { Injectable } from '@angular/core';

import { CodeValue } from '../model';

@Injectable()
export class CodeValueService {
  public getCodeValueText(id: number, groupName: string): string {
    let result = '';

    const codeValue = this.getCodeValue(id, groupName);
    if (codeValue && codeValue.text) {
      result = codeValue.text;
    }

    return result;
  }

  public getCodeValueCode(id: number, groupName: string): string {
    let result = '';

    const codeValue = this.getCodeValue(id, groupName);
    if (codeValue && codeValue.code) {
      result = codeValue.code;
    }

    return result;
  }

  public getCodeValueIdByCode(code: string, groupName: string): number {
    let result = null;

    const codeValue = this.getCodeValueByCode(code, groupName);
    if (codeValue && codeValue.id) {
      result = codeValue.id;
    }

    return result;
  }

  public getCodeValueTextByCode(code: string, groupName: string): string {
    let result = '';

    const codeValue = this.getCodeValueByCode(code, groupName);
    if (codeValue && codeValue.text) {
      result = codeValue.text;
    }

    return result;
  }

  public getCodeValue(id: number, groupName: string): CodeValue | null {
    return this.getCodeValueGroup(groupName).find(c => c.id === +id) ?? null;
  }

  public getCodeValueByCode(code: string, groupName: string): CodeValue | null {
    return this.getCodeValueGroup(groupName).find(c => c.code === code) ?? null;
  }

  public getParentId(groupName: string, id: number): number {
    if (!groupName) {
      console.error('CodeValueService.getParentId groupName is empty');
    }
    let parentId = 0;
    this.getCodeValueGroup(groupName).forEach(item => {
      if (item.id === id) {
        parentId = item.parentId;
      }
    });
    return parentId;
  }

  public getRelatedCodeValues(groupName: string, parentId: any, parentGroup: string): Array<CodeValue> {
    if (!groupName) {
      console.error('CodeValueService.getRelatedCodeValues groupName is empty');
    }

    return this.getCodeValueGroup(groupName)
      .filter(item => item.parentId === parentId && item.parentGroup === parentGroup)
      .sort((a: CodeValue, b: CodeValue) => a.sortOrder - b.sortOrder);
  }

  public getCodeValues(groupName: string, required: boolean): Array<CodeValue> {
    if (!groupName) {
      console.error('CodeValueService.getCodeValues groupName is empty');
    }

    const result = this.getCodeValueGroup(groupName);

    if (required === false) {
      // add first empty item
      // @ts-ignore FIXME: the object being unshifted does not correspond to the CodeValue interface
      result.unshift({ id: null, text: '   ', value: null });
    }

    return result.sort((a: CodeValue, b: CodeValue) => {
      return a.sortOrder - b.sortOrder;
    });
  }

  public getCodeValuesSortByCode(groupName: string, required: boolean): Array<CodeValue> {
    return this.getCodeValues(groupName, required).sort((a: CodeValue, b: CodeValue) => {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
  }

  public getCodeValuesSortByText(groupName: string, required: boolean): Array<CodeValue> {
    return this.getCodeValues(groupName, required).sort((a: CodeValue, b: CodeValue) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });
  }

  private getCodeValueGroup(groupName: string): Array<CodeValue> {
    return (window as any).PhoenixCodeValues[groupName] ?? [];
  }
}
