import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastService } from '../services/toast.service';
import { ApiService } from '../services/api.service';
import * as moment from 'moment';
import { PhxConstants } from '../model';
import { StorageService } from 'ngx-webstorage-service';
import { AppStorageService } from 'src/app/common/services/app-storage.service';
import HTTPResponseStatus = PhxConstants.HTTPResponseStatus;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private refreshingInProgress: boolean;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private endpointsToIgnoreAuthError: Array<string> = ['Account/Logout'];
  private refreshTokenErrorKeys: Array<string> = ['invalid_grant', 'invalid_clientId'];

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private router: Router,
              private toastService: ToastService,
              @Inject(AppStorageService) private storageService: StorageService) {
  }

  private static isDecisionsApiCall(request: HttpRequest<any>) {
    return request.url.indexOf('decisions') !== -1;
  }

  private static addDefaultHeaders(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      if (TokenInterceptor.isDecisionsApiCall(request)) {
        return request.clone({
          setHeaders: {
            Authorization: `Session ${token}`
          }
        });
      }

      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return request;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bearerToken = this.storageService.get('BearerToken');
    const bearerTokenExpiresOn = this.storageService.get('BearerTokenExpiresOn');

    const twoMinutesFromNow = moment().add(+2, 'm');
    const getTokenCall = req.url.indexOf('token') !== -1;
    // if bearer token expires in under 2 minutes, refresh the token automatically
    // Being used to guard against token expiry when using the token in decisions to call microservices
    if (!getTokenCall && bearerTokenExpiresOn && moment(bearerTokenExpiresOn) < twoMinutesFromNow) {
      return this.refreshToken(req, next);
    }

    return next.handle(TokenInterceptor.addDefaultHeaders(req, bearerToken)).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          // in case of 401 http error
          if ((err.status === HTTPResponseStatus.Unauthorized && !this.endpointsToIgnoreAuthError.some(endpoint => req.url.includes(endpoint)))
            || (err.status === HTTPResponseStatus.Forbidden && TokenInterceptor.isDecisionsApiCall(req))) {
            // get refresh tokens
            const refreshToken = this.storageService.get('RefreshToken');

            // if there are tokens then send refresh token request
            if (refreshToken && bearerToken) {
              return this.refreshToken(req, next);
            }

            // otherwise logout and redirect to login page
            return this.logoutAndRedirect(err);
          }

          // in following case (refresh token failed)
          if (err.status === HTTPResponseStatus.BadRequest && err.error && this.refreshTokenErrorKeys.includes(err.error.error)) {
            // logout and redirect to login page
            return this.logoutAndRedirect(err);
          }
        }

        // if error has status neither 401 nor 400 then just return this error
        return throwError(err);
      })
    );
  }

  private logoutAndRedirect(err): Observable<HttpEvent<any>> {
    this.authService.logout(false);
    // only show the toast message if user is logged in
    // we try to automatically log the person in from home page by using the refresh token to generate new access token
    // if failed to generate access token, user does not need to see the toast message and doesn't need to redirect to login page
    if (this.authService.currentUser) {
      this.router.navigate(['/login']);
      this.toastService.logWarning('Your session expired, please login again...');
    }
    return throwError(err);
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);

      return this.apiService.refreshToken().pipe(
        switchMap((res) => {
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(res.access_token);
          // repeat failed request with new token
          return next.handle(TokenInterceptor.addDefaultHeaders(request, res.access_token));
        }),
        catchError(err => {
          this.refreshingInProgress = false;
          return throwError(err);
        })
      );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          // repeat failed request with new token
          return next.handle(TokenInterceptor.addDefaultHeaders(request, token));
        }));
    }
  }
}
