import { CommonService } from './../../services/common.service';
import { PhxToolTipComponent } from './../phx-tooltip/phx-tooltip.component';
import { CodeValueGroups } from './../../model/phx-code-value-groups';
import { StateHistory } from './../../model/state-history';
import { WorkflowService } from './../../services/workflow.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { CodeValueService } from '../../services/code-value.service';
import { PhxLocalizationService } from '../../services/phx-localization.service';
import { PhxConstants } from '../../model';

@Component({
  selector: 'app-phx-state-history-tooltip',
  templateUrl: './phx-state-history-tooltip.component.html',
  styleUrls: ['./phx-state-history-tooltip.component.less']
})
export class PhxStateHistoryTooltipComponent implements OnInit, OnChanges {
  @Input() targetId: string;
  @Input() position: string = 'bottom';
  @Input() entityTypeId: number;
  @Input() entityId: number;
  @Input() currentStatusId: number;

  @Output() viewHistory = new EventEmitter();

  @ViewChild(PhxToolTipComponent)
  phxToolTipComponent: PhxToolTipComponent;

  nextStatusId: number;
  stateHistory: StateHistory;
  statusCodeValueGroups: string;

  constructor(private workflowService: WorkflowService, private codeValueService: CodeValueService, private localizationService: PhxLocalizationService, private commonService: CommonService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entityTypeId) {
      this.statusCodeValueGroups = this.getStatusCodeValueGroups(this.entityTypeId);
    }
    if (changes.entityTypeId || changes.entityId || changes.currentStatusId) {
      if (this.entityTypeId && this.entityId && this.currentStatusId) {
        this.workflowService.getSingleLatestStateHistory(this.entityTypeId, this.entityId).then((response: StateHistory) => {
          this.stateHistory =
            response && response.EntityStatusId === this.currentStatusId
              ? response
              : <StateHistory>{
                  EntityStatusId: this.currentStatusId
                };
          this.stateHistory.Description = this.getHistoryDescription(this.stateHistory);
          this.nextStatusId = this.workflowService.getPreferredEntityStatusId(this.entityTypeId, this.stateHistory.EntityStatusId);
        });
      } else {
        this.stateHistory = null;
      }
    }
  }

  onClickViewHistory(event) {
    this.phxToolTipComponent.close();
    this.viewHistory.emit(event);
  }

  /**
   * Translation for status based on entity
   * Expand this switch case to support more entities
   * @param entityTypeId
   */
  private getStatusCodeValueGroups(entityTypeId: number): string {
    return this.commonService.getStatusCodeValueGroups(entityTypeId);
  }

  /**
   * Get the translated description based on action
   * Default resource is in workflow.stateHistory.{actionCode}
   *
   * If entity based custom description is desired, alternate resource is in workflow.{entityTypeCode}History.{actionCode}
   * @param history
   */
  private getHistoryDescription(history: StateHistory): string {
    return this.commonService.getHistoryDescription(history, this.entityTypeId);
  }
}
