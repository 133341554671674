import { AfterViewChecked, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { BaseComponentOnDestroy } from '../common/epics/base-component-on-destroy';
import { AuthService } from '../common/services/auth.service';
import { PhxConstants, UserProfile } from '../common/model';
import { SidenavService } from './sidenav.service';
import { Navigation, NavigationMenuItem, UserProfileNavigationHistory } from './model';
import { NavigationService } from '../common';
import { TodoService } from '../common/services/todo.service';
import { ConfigurationService } from '../configuration/service/configuration.service';
import { environment } from '../../environments/environment';
import { BookmarkStateService } from '../bookmark-side-menu/bookmark-state.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.less'],
  providers: [BookmarkStateService]
})
export class SidenavComponent extends BaseComponentOnDestroy implements OnInit, AfterViewChecked {
  @Output() navigationBarCollapse = new EventEmitter<boolean>();

  @ViewChild('toggleNavigation') toggleNavigation: ElementRef<HTMLButtonElement>;

  public readonly buildNumber = environment.buildNumber;

  // menu constants
  readonly MENU = 'Menu';
  readonly TODO = 'Todo';

  readonly ADD = 'AddToFavourites';
  readonly ADDA = 'AddToFavouritesA';

  localization = {
    feedbackLabel: 'account.menu.feedbackLabel',
    manageAccountLabel: 'account.menu.manageAccountLabel',
    settingsLabel: 'account.menu.settingsLabel',
    signOutLabel: 'account.menu.signOutLabel',
    menuLabel: 'account.menu.menuLabel',
    editFavoritesLabel: 'account.menu.editFavoritesLabel',
    addToFavoritesLabel: 'account.menu.addToFavoritesLabel'
  };

  selectedSideView = this.MENU;
  currentProfile: UserProfile;
  navigation: Navigation;
  mobileExpanded = false;
  userProfileNavigationHistory: UserProfileNavigationHistory = {
    CreatedDateTime: null,
    Id: null,
    NavigationId: null,
    UserProfileId: null
  };

  isTodoListEnabled = false;
  isNavigationBarCollapsed = false;

  private shouldFocusToggleButton = false;

  constructor(
    private authService: AuthService,
    private sidenavService: SidenavService,
    private navigationService: NavigationService,
    public todoService: TodoService,
    private configurationService: ConfigurationService
  ) {
    super();
  }

  ngOnInit() {
    this.sidenavService
      .getNavigationData()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((data: Navigation) => this.checkSideNav(data));
    this.navigationService
      .sideNavChange$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(sideNavState => {
        this.mobileExpanded = sideNavState;
      });

    this.authService.subscribeProfileChanges(() => this.updateProfile());
    this.authService
      .getCurrentProfile()
      .pipe(take(1), takeUntil(this.isDestroyed$))
      .subscribe(item => {
        this.currentProfile = item;
        this.getUserProfileNavigationHistory();
      });

    this.initToDoListChange();
  }

  ngAfterViewChecked() {
    if (this.shouldFocusToggleButton) {
      this.toggleNavigation.nativeElement.focus();
      this.shouldFocusToggleButton = false;
    }
  }

  toggleNavigationBar(): void {
    this.isNavigationBarCollapsed = !this.isNavigationBarCollapsed;
    this.navigationBarCollapse.emit(this.isNavigationBarCollapsed);
    this.shouldFocusToggleButton = true;
  }

  private updateProfile() {
    this.currentProfile = this.authService.currentProfile;
    this.getUserProfileNavigationHistory();
  }

  /** NOTE: if the user todo list is updated then we open the TODO view */
  initToDoListChange() {
    this.configurationService
      .isFeatureActive$([PhxConstants.FeatureFlags.ShowToDoList])
      .pipe(
        tap(() => (this.isTodoListEnabled = false)),
        filter(featureFlagState => featureFlagState[PhxConstants.FeatureFlags.ShowToDoList]),
        tap(() => (this.isTodoListEnabled = true)),
        switchMap(() => this.todoService.todoListChanged$),
        filter(() => this.selectedSideView !== this.TODO),
        takeUntil(this.isDestroyed$)
      )
      .subscribe(() => {
        this.selectedSideView = this.TODO;
      });
  }

  async getUserProfileNavigationHistory() {
    try {
      this.userProfileNavigationHistory = await this.sidenavService.getUserProfileNavigationHistoryLastNavigationId(this.currentProfile.Id);
    } catch (e) {
      // don't care about this error, just need a catch for promise rejection
    }
    this.checkSideNav(this.navigation);
  }

  accordionGroupOpenChange(event, navItem) {
    if (event) {
      this.sidenavService.saveUserProfileNavigationHistory(navItem.Id, this.currentProfile.Id);
    }
  }

  /**
   * Toggles collapsible in the side nav menu
   * This is called from both top level and sub menu item
   * @param navItem The menu item that user clicked on
   */
  toggleCollapsed(navItem: NavigationMenuItem) {
    // check whether user clicked on the same menu item of a different one
    if (this.userProfileNavigationHistory.NavigationId !== navItem.Id) {
      // close the previously open menu item
      this.closeOpenCollapsible();
      // set the NavigationId to current open menu item
      this.userProfileNavigationHistory.NavigationId = navItem.Id;
      // update UI
      this.checkSideNav(this.navigation);
      // save menu selection history
      this.sidenavService.saveUserProfileNavigationHistory(navItem.Id, this.currentProfile.Id);
    } else {
      // toggle the IsOpen property as user clicked on the same menu item
      navItem.IsOpen = !navItem.IsOpen;
      // update UI
      // save menu selection history.
      // If the item is open, use its own id as the navigated id, if the current item is closed, use its parent's id as the navigated id
      // current item being closed means that the parent is the active navigated menu item
      this.sidenavService.saveUserProfileNavigationHistory(!navItem.IsOpen ? navItem.ParentId : navItem.Id, this.currentProfile.Id);
    }
  }

  /**
   * Click handler for a tags in side nav
   * Sets user profile navigation history
   * @param id id of the menu item clicked
   */
  onSideNavLinkClick(id: number) {
    this.mobileExpanded = false;
    this.sidenavService.saveUserProfileNavigationHistory(id, this.currentProfile.Id);
  }

  /**
   * Updates sidenav data and check which menu item is open
   * @param data array of menu items
   */
  checkSideNav(data: Navigation) {
    if (data && data.NavigationItems) {
      data.NavigationItems.forEach((element: NavigationMenuItem) => {
        this.isCollapsibleOpen(element);
      });

      this.navigation = { ...data, NavigationItems: data.NavigationItems.filter(i => i.Code !== 'dashboard') };
    }
  }

  /**
   * Checks whether a given menu item is open by comparing with user's last navigation id
   * Does a deep lookup of menu item till a child is found that should be open, then sets IsOpen property all the way up the hierarchy
   * @param navigationItem given menu item
   */
  isCollapsibleOpen(navigationItem: NavigationMenuItem) {
    if (navigationItem && navigationItem.Id === this.userProfileNavigationHistory.NavigationId) {
      navigationItem.IsOpen = true;
      return true;
    }

    if (navigationItem.HasChildren) {
      for (const navItem of navigationItem.Children) {
        if (this.isCollapsibleOpen(navItem)) {
          navigationItem.IsOpen = true;
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Finds and closes the currently active menu item
   */
  closeOpenCollapsible(navigationItems = this.navigation.NavigationItems) {
    for (const navItem of navigationItems) {
      if (navItem.Id === this.userProfileNavigationHistory.NavigationId) {
        navItem.IsOpen = false;
        return true;
      } else if (navItem.HasChildren && navItem.Children && navItem.Children.length > 0) {
        if (this.closeOpenCollapsible(navItem.Children)) {
          return true;
        }
      }
    }
    return false;
  }
}
