export class PhxDocumentFileUploadConfiguration {
  entityTypeId: number;
  entityId: number;
  documentTypeId: number;
  limitMultiFileUploads?: number;
  UploadTitle?: string;
  SupportedFileExtensions?: string;
  customId1?: number;
  customId2?: number;
  customMethodata?: number;
  customDateTime?: string;
  customComment?: string;
  description?: string;
  isFinalDocument?: boolean;
  isAsync?: boolean;
  additionalInformation?: string;
  isPreviewWithCompliance?: boolean;
  customUiConfig?: {
    objectDate: {
      value: Date | string;
      isRequired: boolean;
      label: string;
      helpBlock: string;
    };
    objectComment: {
      value: string;
      isRequired: boolean;
      label: string;
      helpBlock: string;
      minlength: number;
      maxlength: number;
    };
    objectDocumentType?: {
      value?: number;
      isRequired: boolean;
      label: string;
      helpBlock: string;
    };
    tertiaryAction?: {
      text: string;
      disabled: () => boolean;
      action: () => void;
    };
  };

  constructor(params: PhxDocumentFileUploadConfiguration) {
    Object.assign(this, params);
  }
}
