import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AzSearchStoreExtensionType,
  GlobalSearchFieldType,
  IActiveGsAzSearchStoreExtensionInfo,
  IGsConfiguration
} from '../models/global-search';

import { PhxConstants } from '../../common/model';
import { SearchDirection } from '../az-search-store-extension/model/az-search-store-model-extensions';
import { AzSearchStoreExtension } from '../az-search-store-extension/az-search-store-extension';
import { ApiService, CommonService } from '../../common';
import { IPaymentDetails } from '../interfaces/global-search';
import { IChipClickHandler, IPaymentClickHandler, ITrackedSearchOperations, ITranRoutingParams, ITransactionClickHandler, IWorkerRoutingParams, IWorkOrderClickHandler, IWORoutingParams } from '../interfaces/global-search-service';
import { AuthService } from '../../common/services/auth.service';

import { GlobalSearchConstants } from '../models/global-search-constants';
import { FacetConfigUiType, IFacetsConfig } from '../models/global-search-facet';
import { Store } from 'azsearchstore';
import { Subject } from 'rxjs';

// Helper method to initialize facets for AzSearchStoreExtension
const initializeFacets = (facetsConfig: IFacetsConfig, searchStoreExtension: AzSearchStoreExtension, typeDeclorations) => {
  facetsConfig.map(facetConfig => {
    let dataType: Store.RangeDataType | Store.CheckboxDataType;

    switch (typeDeclorations[facetConfig.SearchFieldName]) {
      case GlobalSearchFieldType.Number:
        dataType = 'number';
        break;
      case GlobalSearchFieldType.Date:
        dataType = 'date';
        break;
      case GlobalSearchFieldType.String:
        dataType = 'string';
        break;
      case GlobalSearchFieldType.Collection:
        dataType = 'collection';
        break;
    }

    switch (facetConfig.UiType) {
      case FacetConfigUiType.Checkbox:
      case FacetConfigUiType.Dropdown: {
        searchStoreExtension.addCheckboxFacet(facetConfig.SearchFieldName, dataType as Store.CheckboxDataType, 50);
        break;
      }
      case FacetConfigUiType.Range: {
        searchStoreExtension.addRangeFacet(facetConfig.SearchFieldName, dataType as Store.RangeDataType, facetConfig.Min, facetConfig.Max);
        break;
      }
      default: {
        return;
      }
    }
  });
};

const setUpAzSearchStoreExtension = (gsConfiguration: IGsConfiguration, searchStoreExtension: AzSearchStoreExtension, typeDeclorations) => {
  searchStoreExtension.setConfig(gsConfiguration.config);
  searchStoreExtension.updateSearchParameters(gsConfiguration.searchParameters);
  searchStoreExtension.updateSuggestionsParameters(gsConfiguration.suggestionParameters);
  initializeFacets(gsConfiguration.facetsConfig, searchStoreExtension, typeDeclorations);
};

interface IGlobalSearchState {
  // Currently active searchStore that's used across the app (i.e from anywhere in the app)
  ActiveGlobalAzSearchStoreExtensionInfo: IActiveGsAzSearchStoreExtensionInfo;
  // Subscribable subject on `ActiveGlobalAzSearchStoreExtensionInfo`.
  ActiveGlobalAzSearchStoreExtensionInfo$: Subject<IActiveGsAzSearchStoreExtensionInfo>;
  AzSearchStoreExtensionWO: AzSearchStoreExtension;
  AzSearchStoreExtensionTransaction: AzSearchStoreExtension;
  AzSearchStoreExtensionPayment: AzSearchStoreExtension;
}

export const GlobalSearchState: IGlobalSearchState = {
  ActiveGlobalAzSearchStoreExtensionInfo: null,
  ActiveGlobalAzSearchStoreExtensionInfo$: new Subject<IActiveGsAzSearchStoreExtensionInfo>(),
  AzSearchStoreExtensionWO: new AzSearchStoreExtension(),
  AzSearchStoreExtensionTransaction: new AzSearchStoreExtension(),
  AzSearchStoreExtensionPayment: new AzSearchStoreExtension(),
};
// Initialize to WorkOrder search
setUpAzSearchStoreExtension(GlobalSearchConstants.GsWorkOrderConfiguration, GlobalSearchState.AzSearchStoreExtensionWO, GlobalSearchConstants.GsWorkOrderTypeDeclarations);
setUpAzSearchStoreExtension(GlobalSearchConstants.GsTransactionConfiguration, GlobalSearchState.AzSearchStoreExtensionTransaction, GlobalSearchConstants.GsTransactionTypeDeclarations);
setUpAzSearchStoreExtension(GlobalSearchConstants.GsPaymentConfiguration, GlobalSearchState.AzSearchStoreExtensionPayment, GlobalSearchConstants.GsPaymentTypeDeclarations);
GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo = {
  AzSearchStoreExtension: GlobalSearchState.AzSearchStoreExtensionWO,
  Type: AzSearchStoreExtensionType.WorkOrder
};
GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo$.next(GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo);

@Injectable()
export class GlobalSearchService implements ITrackedSearchOperations, IChipClickHandler, IWorkOrderClickHandler, ITransactionClickHandler, IPaymentClickHandler {

  private currState: IGlobalSearchState = { ...GlobalSearchState };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this.authService.getUserContext().then(userContext => {
      if (!userContext || !userContext.User) {
        return;
      }

      this.setGlobalSearchEnvironmentNameInState(userContext.User.EnvironmentName);
    });
  }

  private static getActiveSearchStore(): AzSearchStoreExtension {
    if (!GlobalSearchState || !GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo) {
      return;
    }
    return GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo.AzSearchStoreExtension;
  }

  private static getActiveSearchStoreType(): AzSearchStoreExtensionType {
    if (!GlobalSearchState || !GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo) {
      return;
    }
    return GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo.Type;
  }

  trackedExecuteSearchFromSearchBar() {
    if (this.router.url !== '/global-search/results') {
      this.router.navigate(['/global-search/results'], { relativeTo: this.activatedRoute.parent });
    }
    GlobalSearchService.getActiveSearchStore().searchAndResetFacets();
  }

  trackedFacetSearch(): Promise<void> {
    return GlobalSearchService.getActiveSearchStore().searchAndPreserveFacets();
  }

  trackedToggleCheckboxAndSearch(fieldName: string, value: string | number): Promise<void> {
    return GlobalSearchService.getActiveSearchStore().toggleCheckboxFacetAndSearch(fieldName, value);
  }

  trackedSetFacetRangeAndSearch(fieldName: string, lowerBound: number | Date, upperBound: number | Date): Promise<void> {
    return GlobalSearchService.getActiveSearchStore().setFacetRangeAndSearch(fieldName, lowerBound, upperBound);
  }

  trackedSuggest(azSearchStoreExtension: AzSearchStoreExtension): Promise<void> {
    return azSearchStoreExtension.suggest();
  }

  trackedSort(fieldName: string, direction: SearchDirection) {
    GlobalSearchService.getActiveSearchStore()
      .sort(fieldName, direction)
      .then(() => {
        if (direction === SearchDirection.None) {
          return;
        }
      });
  }

  onWorkOrderCardClick(woRoutingParams: IWORoutingParams, cardIndex: number) {
    this.trackedNavToWorkOrder(woRoutingParams, cardIndex);
  }

  onWorkOrderListRowClick(woRoutingParams: IWORoutingParams, cardIndex: number) {
    this.trackedNavToWorkOrder(woRoutingParams, cardIndex);
  }

  onTransactionCardClick(cardObj: any, cardIndex: number) {
    const transactionParam: ITranRoutingParams = this.getTranRoutingParams(cardObj);
    this.trackedNavToTransaction(transactionParam, cardIndex);
  }

  onTransactionListRowClick(cardObj, cardIndex: number) {
    const transactionParam: ITranRoutingParams = this.getTranRoutingParams(cardObj);
    this.trackedNavToTransaction(transactionParam, cardIndex);
  }

  onPaymentCardClick(paymentDetails: IPaymentDetails, cardIndex: number) {
    this.trackedNavToPayment(paymentDetails, cardIndex);
  }

  async onPaymentListRowClick(paymentDetails: IPaymentDetails, cardIndex: number) {
    this.trackedNavToPayment(paymentDetails, cardIndex);
  }

  async onWorkOrderChipClick(cardObj: any, cardIndex: number) {
    const activeSearchType = GlobalSearchService.getActiveSearchStoreType();
    if (activeSearchType === AzSearchStoreExtensionType.WorkOrder) {
      return;
    }
    switch (activeSearchType) {
      case AzSearchStoreExtensionType.Transaction:
        const woRoutingParams: IWORoutingParams = this.getWORoutingParams(cardObj);
        this.trackedNavToWorkOrder(woRoutingParams, cardIndex);
        break;
      case AzSearchStoreExtensionType.Payment:
        this.activateWorkOrderGlobalSearchInState();
        await this.searchByFilter(() =>
          GlobalSearchService.getActiveSearchStore().setIncludedInFilter('Associations/PaymentIds', cardObj.Associations.PaymentId));
        break;
      default:
        break;
    }
  }

  async onPaymentChipClick(cardObj: any, cardIndex: number) {
    const activeSearchType = GlobalSearchService.getActiveSearchStoreType();
    if (activeSearchType === AzSearchStoreExtensionType.Payment) {
      return;
    }

    switch (activeSearchType) {
      case AzSearchStoreExtensionType.WorkOrder:
        this.activatePaymentGlobalSearchInState();
        await this.searchByFilter(() =>
          GlobalSearchService.getActiveSearchStore().setIncludedInFilter('Associations/WorkOrderIds', cardObj.Associations.WorkOrderId));
        break;
      case AzSearchStoreExtensionType.Transaction:
        this.activatePaymentGlobalSearchInState();
        await this.searchByFilter(() =>
          GlobalSearchService.getActiveSearchStore().setIncludedInFilter('Associations/TransactionHeaderIds', cardObj.Associations.TransactionId));
        break;
    }
  }

  async onTransactionChipClick(cardObj: any, cardIndex: number) {
    const activeSearchType = GlobalSearchService.getActiveSearchStoreType();
    if (activeSearchType === AzSearchStoreExtensionType.Transaction) {
      return;
    }

    switch (activeSearchType) {
      case AzSearchStoreExtensionType.WorkOrder:
        this.activateTransactionGlobalSearchInState();
        await this.searchByFilter(() =>
          GlobalSearchService.getActiveSearchStore().setEqualsGlobalFilter('Associations/WorkOrderId', cardObj.Associations.WorkOrderId));
        break;
    }
  }

  async onWorkerChipClick(cardObj: any, cardIndex: number) {
    const workerRoutingParams = this.getWorkerRoutingParams(cardObj);
    this.trackedNavToWorker(workerRoutingParams, cardIndex);
  }

  async onClientChipClick(cardObj: any, cardIndex: number) {
    const organizationId = cardObj.Associations.ClientOrganizationId;
    try {
      const latestVersionId = await this.getLatestOrganizationId(organizationId);
      this.trackedNavToClient(latestVersionId, cardIndex);
    } catch (ex) {
    }
  }

  async onSupplierChipClick(cardObj: any, cardIndex: number) {
    const organizationId = cardObj.Associations.SubvendorOrganizationId;
    try {
      const latestVersionId = await this.getLatestOrganizationId(organizationId);
      this.trackedNavToClient(latestVersionId, cardIndex);
    } catch (ex) {
    }
  }

  onWorkOrderSuggestionClick(woRoutingParams: IWORoutingParams) {
    this.trackedNavToWorkOrder(woRoutingParams, null);
  }

  onTransactionSuggestionClick(tranSuggestion: any) {
    const tranRoutingParams = this.getTranRoutingParams(tranSuggestion);
    this.trackedNavToTransaction(tranRoutingParams, null);
  }

  onPaymentSuggestionClick(paymentDetails: IPaymentDetails) {
    this.trackedNavToPayment(paymentDetails, null);
  }

  private trackedNavToWorkOrder(woRoutingParams: IWORoutingParams, cardIndex: number) {
    const navigatePath = `/next/workorder/${woRoutingParams.AssignmentId}/${woRoutingParams.WorkOrderId}/${woRoutingParams.WorkOrderVersionId}/core`;
    this.router.navigate([navigatePath], { relativeTo: this.activatedRoute.parent }).catch(err => { });
  }

  private trackedNavToTransaction(tranRoutingParams: ITranRoutingParams, cardIndex: number) {
    const isManualDraft = tranRoutingParams.IsDraft && tranRoutingParams.TransactionTypeId === PhxConstants.TransactionType.Manual;
    if (isManualDraft) {
      this.router.navigate(['/next', 'transaction', tranRoutingParams.Id, PhxConstants.TransactionNavigationName.detail]);
    } else {
      this.router.navigate(['/next', 'transaction', tranRoutingParams.Id, PhxConstants.TransactionNavigationName.summary]);
    }
  }

  private trackedNavToWorker(workerRoutingParams: IWorkerRoutingParams, cardIndex: number) {
    this.router.navigate(['/next', 'contact', workerRoutingParams.ContactId, 'profile', workerRoutingParams.ProfileType, workerRoutingParams.ProfileId]);
  }

  private trackedNavToClient(clientOrganizationId: number, cardIndex: number): void {
    const organizationTab = PhxConstants.OrganizationNavigationName.details;
    const navigatePath = `/next/organization/${clientOrganizationId}/${organizationTab}`;
    this.router.navigate([navigatePath], { relativeTo: this.activatedRoute.parent }).catch(err => { });
  }

  trackedNavToPayment(paymentDetails: IPaymentDetails, cardIndex: number) {
    this.router.navigate(['/next/payment/details', {
      currencyId: paymentDetails.CurrencyId,
      payeeTypeId: paymentDetails.PayeeTypeId,
      paymentDate: paymentDetails.PaymentDate,
      id: paymentDetails.PaymentId,
      paymentNumber: paymentDetails.PaymentNumber,
      paymentTotal: paymentDetails.PaymentTotal,
      statusId: paymentDetails.StatusId
    }]);
  }

  /**
   * Does a search by a set with some set of filters on the active search store. Clears the filters after performing the search.
   *
   * @param filterSetter A function that, upon execution, sets the filters on the currently active search store.
   */
  private async searchByFilter(filterSetter: () => void) {
    const activeSearchStoreExtension = GlobalSearchService.getActiveSearchStore();
    const existingInput = activeSearchStoreExtension.getState().parameters.input;
    const cleanUpHelper = () => {
      activeSearchStoreExtension.clearAllGlobalFilters();
      activeSearchStoreExtension.setInput(existingInput);
    };
    filterSetter();
    activeSearchStoreExtension.setInput('');
    activeSearchStoreExtension
      .searchAndResetFacets()
      .then(() => cleanUpHelper())
      .catch(() => cleanUpHelper());
  }

  ///////////////////////////
  // Miscellaneous helpers //
  ///////////////////////////

  private async getLatestOrganizationId(organizationId: number) {
    return this.apiService.queryWithPromise<number>(`org/latestVersionId/${organizationId}`);
  }

  private getProfileType(rowdata) {
    return this.commonService.getUserProfileTypeFromDescription(rowdata.ProfileType || rowdata.WorkerProfileType);
  }

  private getContactId(rowdata): number {
    return rowdata.Associations.WorkerContactId ? rowdata.Associations.WorkerContactId : 0;
  }

  private getProfileId(rowdata): number {
    return rowdata.Associations.WorkerProfileId;
  }

  private getWORoutingParams(itemData): IWORoutingParams {
    return {
      AssignmentId: itemData.AssignmentId,
      WorkOrderId: itemData.WorkOrderId,
      WorkOrderVersionId: itemData.WorkOrderVersionId
    } as IWORoutingParams;
  }

  private getWorkerRoutingParams(itemData): IWorkerRoutingParams {
    return {
      ContactId: this.getContactId(itemData),
      ProfileType: this.getProfileType(itemData),
      ProfileId: this.getProfileId(itemData)
    } as IWorkerRoutingParams;
  }

  private getTranRoutingParams(itemData): ITranRoutingParams {
    return {
      Id: itemData.TransactionId,
      IsDraft: itemData.IsDraft,
      TransactionTypeId: itemData.TransactionTypeId
    } as ITranRoutingParams;
  }

  activateWorkOrderGlobalSearchInState() {
    const azSearchStoreExtensionInfo = {
      AzSearchStoreExtension: GlobalSearchState.AzSearchStoreExtensionWO,
      Type: AzSearchStoreExtensionType.WorkOrder
    };
    this.currState.ActiveGlobalAzSearchStoreExtensionInfo = azSearchStoreExtensionInfo;
    GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo = azSearchStoreExtensionInfo;
    GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo$.next(azSearchStoreExtensionInfo);
  }

  activateTransactionGlobalSearchInState() {
    const azSearchStoreExtensionInfo = {
      AzSearchStoreExtension: GlobalSearchState.AzSearchStoreExtensionTransaction,
      Type: AzSearchStoreExtensionType.Transaction
    };
    GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo = azSearchStoreExtensionInfo;
    GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo$.next(azSearchStoreExtensionInfo);
  }

  activatePaymentGlobalSearchInState() {
    const azSearchStoreExtensionInfo = {
      AzSearchStoreExtension: GlobalSearchState.AzSearchStoreExtensionPayment,
      Type: AzSearchStoreExtensionType.Payment
    };
    GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo = azSearchStoreExtensionInfo;
    GlobalSearchState.ActiveGlobalAzSearchStoreExtensionInfo$.next(azSearchStoreExtensionInfo);
  }

  setGlobalSearchEnvironmentNameInState(environmentName: string) {
    let suggestionsParamUpdate = { filter: `IsDeleted eq false` };
    if ((environmentName || '') !== '') {
      GlobalSearchState.AzSearchStoreExtensionWO.setEqualsGlobalFilter('EnvironmentName', environmentName);
      GlobalSearchState.AzSearchStoreExtensionPayment.setEqualsGlobalFilter('EnvironmentName', environmentName);
      GlobalSearchState.AzSearchStoreExtensionTransaction.setEqualsGlobalFilter('EnvironmentName', environmentName);

      suggestionsParamUpdate = { filter: `IsDeleted eq false and EnvironmentName eq '${environmentName}'` };
    }

    GlobalSearchState.AzSearchStoreExtensionWO.updateSuggestionsParameters(suggestionsParamUpdate);
    GlobalSearchState.AzSearchStoreExtensionPayment.updateSuggestionsParameters(suggestionsParamUpdate);
    GlobalSearchState.AzSearchStoreExtensionTransaction.updateSuggestionsParameters(suggestionsParamUpdate);
  }

}
