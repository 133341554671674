import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CommandResponse, EntityList, PhxConstants } from '../model';
import { PhxNote, PhxNoteStore } from '../model/phx-note';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({ providedIn: 'root' })
export class NoteService {
  private notesCache: Array<PhxNoteStore> = [];
  private notes: BehaviorSubject<Array<PhxNoteStore>> = new BehaviorSubject<Array<PhxNoteStore>>([]);
  private loadNoteSubscription: { [key: string]: Subscription } = {};
  private readonly unreadNotificationEnabledEntities = [
    PhxConstants.EntityType.WorkOrder,
    PhxConstants.EntityType.Organization,
    PhxConstants.EntityType.Contact
  ];

  constructor(private apiService: ApiService) {
  }

  get notes$() {
    return this.notes.asObservable();
  }

  private setNotes(entityTypeId: number, entityId: number, notes: Array<PhxNote>) {
    notes = notes || [];
    const noteUnreadCount = notes.filter(a => !a.IsRead).length;
    const noteCount = notes.length;
    const showUnreadNoteNotification = this.unreadNotificationEnabledEntities.includes(entityTypeId) && noteUnreadCount > 0;

    const cache = this.notesCache.find(x => x.entityId === entityId && x.entityTypeId === entityTypeId);
    if (cache) {
      cache.notes = notes;
      cache.noteUnreadCount = noteUnreadCount;
      cache.noteCount = noteCount;
      cache.showUnreadNoteNotification = showUnreadNoteNotification;
    } else {
      this.notesCache.push({
        entityId,
        entityTypeId,
        notes,
        noteUnreadCount,
        noteCount,
        showUnreadNoteNotification
      });
    }
    this.notes.next(this.notesCache);
  }

  private clearNotes(entityTypeId: number, entityId: number) {
    if (!entityId && !entityTypeId) {
      this.notesCache = [];
    } else {
      const cacheIdx = this.notesCache.findIndex(x => x.entityId === entityId && x.entityTypeId === entityTypeId);
      if (cacheIdx !== -1) {
        this.notesCache.splice(cacheIdx, 1);
      }
    }
    this.notes.next(this.notesCache);
  }

  loadNotes(entityTypeId: number, entityId: number, isDestroyed: Subject<boolean>, forceRefresh: boolean = false, showLoader: boolean = true) {
    const cache = this.notesCache.find(x => x.entityId === entityId && x.entityTypeId === entityTypeId);
    if (cache && !forceRefresh) {
      return;
    }

    const key = `${entityTypeId}_${entityId}`;
    if (this.loadNoteSubscription[key]) {
      this.loadNoteSubscription[key].unsubscribe();
      this.loadNoteSubscription[key] = null;
    }
    this.loadNoteSubscription[key] = this.apiService.query<EntityList<PhxNote>>(`note/entityType/${entityTypeId}/entity/${entityId}`, showLoader)
      .pipe(takeUntil(isDestroyed))
      .subscribe(result => {
        const notes = result ? result.Items || [] : [];
        this.setNotes(entityTypeId, entityId, notes);
      }, () => {
        this.clearNoteCache(entityTypeId, entityId);
      }, () => {
        this.loadNoteSubscription[key] = null;
      });
  }

  saveNote(command): Observable<CommandResponse> {
    return from(this.apiService.command('SaveNote', command));
  }

  markRead(command): Observable<CommandResponse> {
    return from(this.apiService.command('MarkUnreadNote', command));
  }

  markAllRead(command): Observable<CommandResponse> {
    return from(this.apiService.command('MarkAllReadNote', command));
  }

  deleteNote(command): Observable<CommandResponse> {
    return from(this.apiService.command('DeleteNote', command));
  }

  clearNoteCache(entityTypeId: number = null, entityId: number = null) {
    this.clearNotes(entityTypeId, entityId);
    if (entityTypeId && entityId) {
      const key = `${entityTypeId}_${entityId}`;
      if (this.loadNoteSubscription[key]) {
        this.loadNoteSubscription[key].unsubscribe();
        this.loadNoteSubscription[key] = null;
      }
    }
  }
}
