<div
  class="row"
  [ngStyle]="{ paddingTop: paddingTop }"
>
  <div
    [ngClass]="{
      'col-xs-12 padding-0': true,
      'col-lg-10 fix-header': isPositionFixed
    }"
    #header
  >
    <div class="padding-0">
      <div class="v-h-group">
        <div
          class="v-h-img-col"
          *ngIf="initials"
        >
          <div class="v-h-img">
            <app-phx-profile-picture
              [showInitials]="true"
              [initials]="initials"
              [statusType]="entityStatus"
            ></app-phx-profile-picture>

            <div
              [class]="'status-icon-container status-' + entityStatus"
              *ngIf="entityStatusIcon"
            >
              <span class="material-icons-outlined">
                {{ entityStatusIcon }}
              </span>
            </div>
          </div>
        </div>
        <div class="v-h-data-col">
          <div
            class="v-h-path"
            *ngIf="entityPath"
          >
            {{ entityPath }}
          </div>
          <div>
            <app-phx-switch-version
              [isShowAllVersionsButtonVisible]="hasVersionsPanel$ | async"
              [entityType]="entityName"
              [currentVersion]="currentVersion"
              [customEntityName]="customEntityName"
              [entityVersions]="entityVersions"
              [statusGroup]="statusGroup"
              (selected)="selectVersion($event)"
              (expand)="showVersionsPanel()"
            >
            </app-phx-switch-version>
          </div>
        </div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
