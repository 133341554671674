import { Component, Input, OnInit } from '@angular/core';
import { CommonService, PhxConstants } from 'src/app/common';
import { IPaymentDetails } from '../../../interfaces/global-search';
import { GlobalSearchService } from '../../../services/global-search-service';
import {GlobalSearchUtil} from '../../../util/GlobalSearchUtil';

@Component({
  selector: 'app-global-search-suggestion-payment',
  templateUrl: './global-search-suggestion-payment.component.html',
  styleUrls: ['./global-search-suggestion-payment.component.less']
})
export class GlobalSearchSuggestionPaymentComponent implements OnInit {
  public phxConstants: typeof PhxConstants = null;
  @Input() paymentData: Array<IPaymentDetails>;
  codeValueGroups: any;

  constructor(
      private commonService: CommonService,
      private globalSearchService: GlobalSearchService,
      public gsUtil: GlobalSearchUtil) {
    this.phxConstants = PhxConstants;
    this.codeValueGroups = this.commonService.CodeValueGroups;
  }

  ngOnInit() {}

  onPaymentSuggestionClick(paymentDetails: IPaymentDetails) {
    this.globalSearchService.onPaymentSuggestionClick(paymentDetails);
  }
}
